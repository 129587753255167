import React from 'react';
import { observer } from 'mobx-react';

import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';

import List from '@material-ui/core/List';

import RateCallCategoryItemOption from './RateCallCategoryItemOption';

type RateCallCategoryOptionsProps = {
    call_rate_id: number;
    category_id: number;
};

const RateCallCategoryOptions = ({ call_rate_id, category_id }: RateCallCategoryOptionsProps) => {
    const options = callRateCategoryStore.findById(category_id)?.options || [];

    return (
        <List dense>
            {options.map(({ category_option_id, title }) => (
                <RateCallCategoryItemOption
                    key={category_option_id}
                    call_rate_id={call_rate_id}
                    category_option_id={category_option_id}
                    category_id={category_id}
                    title={title}
                />
            ))}
        </List>
    );
};

export default observer(RateCallCategoryOptions);
