import React from 'react';
import { observer } from 'mobx-react';
import { Accordion, Icon, Header } from 'semantic-ui-react';
import Grid from '@material-ui/core/Grid';

import estateStore from '~/stores/estateStore';

import { useToggle } from '~ui/Modal';

const YoutubeEmbed = ({ videoId }: { videoId: string }) => (
    <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
    ></iframe>
);

const RutubeEmbed = ({ videoId }: { videoId: string }) => (
    <iframe
        width="560"
        height="315"
        frameBorder="0"
        src={`https://rutube.ru/play/embed/${videoId}/`}
        allow="clipboard-write; autoplay"
        allowFullScreen
    ></iframe>
);

const VkVideoEmbed = ({ videoId }: { videoId: string }) => {
    const [oid, id] = videoId.split('_');

    return (
        <iframe
            src={`https://vk.com/video_ext.php?oid=${oid}&id=${id}&hd=2&autoplay=0`}
            width="560"
            height="315"
            frameBorder="0"
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
            allowFullScreen
        ></iframe>
    );
};

const VideoEmbed = ({ serviceId, videoId, count }: { serviceId: number; videoId: string; count: number }) => {
    return (
        <Grid item xs={12}>
            {serviceId === 1 && <YoutubeEmbed videoId={videoId} />}
            {serviceId === 2 && <RutubeEmbed videoId={videoId} />}
            {serviceId === 3 && <VkVideoEmbed videoId={videoId} />}
        </Grid>
    );
};

const VideoBlockShow = ({ estate_id }: { estate_id: number }) => {
    const [showAccordion, toggleAccordion] = useToggle();

    const { item } = estateStore.getItem(estate_id);
    if (!item || !item.videos) return null;

    const { videos } = item;

    return (
        <Accordion>
            <Accordion.Title active={showAccordion} onClick={toggleAccordion}>
                <Header size="small">
                    <Header.Content>
                        <Icon name="dropdown" />
                        <Icon name="video play" color="red" /> Видео ролик{videos.length > 1 ? 'и' : ''}
                    </Header.Content>
                </Header>
            </Accordion.Title>
            <Accordion.Content active={showAccordion}>
                {showAccordion && (
                    <Grid container alignItems="center">
                        {videos.map(({ videoId, serviceId }) => (
                            <VideoEmbed key={videoId} videoId={videoId} serviceId={serviceId} count={videos.length} />
                        ))}
                    </Grid>
                )}
            </Accordion.Content>
        </Accordion>
    );
};

export default observer(VideoBlockShow);
