import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ErrorMessage, MessageTitle } from '~ui/Message';

const GlobalError = ({ error }: { error?: string }) => (
    <ErrorMessage>
        <MessageTitle>Произошла ошибка</MessageTitle>
        <List style={{ color: 'black' }}>
            <ListItem button onClick={() => document.location.reload()}>
                Перезагрузить страницу
            </ListItem>
            <ListItem
                button
                onClick={() => {
                    window.history.back();
                    setTimeout(() => {
                        document.location.reload();
                    }, 250);
                }}
            >
                Вернуться назад
            </ListItem>
            <ListItem button onClick={() => (document.location.href = '/')}>
                Перейти на главную
            </ListItem>
        </List>

        <br />
        {error && <div>Ошибка: {error}</div>}
    </ErrorMessage>
);

export default GlobalError;
