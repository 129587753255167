import React, { Component } from 'react';
import { Router } from 'react-router-dom';

import { observer } from 'mobx-react';

import ruLocale from 'date-fns/locale/ru';

import * as crmChangesApi from '~/api/crmChangesApi';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';

import GlobalError from './components/Base/GlobalError';
import LoginForm from './components/LoginForm';
import history from './history';
import MainRouter from './mainRouter';
import muiThemeConfig from './muiTheme';
import authStore from './stores/authStore';
import commonStore from './stores/commonStore';

type AppState = {
    isError: string | null;
};

const outerTheme = createMuiTheme(muiThemeConfig);

@observer
class App extends Component<Record<string, never>, AppState> {
    state = {
        isError: null
    };

    async componentDidMount(): Promise<void> {
        if (authStore.token) {
            authStore.whatsUp();
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('componentDidCatch', error);
        console.log('errorInfo', errorInfo);
        crmChangesApi.sendUiError(error, errorInfo, 'App');
        this.setState({ isError: error.message });
    }

    render() {
        if (this.state.isError) {
            return <GlobalError error={this.state.isError} />;
        }
        if (!authStore.loggedIn) {
            return (
                <ThemeProvider theme={outerTheme}>
                    <LoginForm />
                </ThemeProvider>
            );
        }

        const { expandedMenu, isMobile, fullSizePage } = commonStore;

        return (
            <ThemeProvider theme={outerTheme}>
                <LocalizationProvider dateAdapter={DateFnsAdapter as any} locale={ruLocale}>
                    <div className={`crm-Main ${expandedMenu ? 'crm-Main__expanded' : ''}`}>
                        <Router history={history}>
                            <MainRouter location={history.location} fullSize={!isMobile && fullSizePage} />
                        </Router>
                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        );
    }
}

export default App;
