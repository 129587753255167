import React from 'react';
import { observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';

import callRateStore from '~/stores/cell/callRateStore';
import useFilterChange from '~/stores/hooks/useFilterChange';

import ListFilterWrapper from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';

import ButtonGroup from '~/components/Base/ButtonGroup';
import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';
import UsersAndGroupsDropdownNew from '~/components/Lists/Common/UsersAndGroupsDropdownNew';

const FilterCallRates = (props: ListFilterWrapperProps) => {
    const { caller_user_id, caller_group_id } = callRateStore.listFilter;
    const [category_id, handleChangeCategory] = useFilterChange(callRateStore, 'category_id');

    const handleChangeCaller = (_, { name, value }: { name: 'group_id' | 'major_user_id'; value: number[] }) => {
        callRateStore.changeFilter(name === 'group_id' ? 'caller_group_id' : 'caller_user_id', value);
    };

    return (
        <>
            <Menu.Item className="crm-List__filter_dropdown">
                <div className="ui form mini">
                    <ButtonGroup
                        options={callRateCategoryStore.allCategoriesDropdown}
                        value={category_id}
                        name="category_id"
                        handleChange={(_, { value }) => handleChangeCategory(value)}
                    />
                </div>
            </Menu.Item>
            <Menu.Item className="crm-List__filter_dropdown">
                <div className="ui form mini">
                    <UsersAndGroupsDropdownNew
                        placeholder="Кого оценили"
                        group_id={caller_group_id}
                        major_user_id={caller_user_id}
                        handleChange={handleChangeCaller}
                    />
                </div>
            </Menu.Item>
        </>
    );
};

export default ListFilterWrapper(callRateStore, observer(FilterCallRates));
