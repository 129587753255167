import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';

import * as userApi from '~/api/userApi';

import authStore from '~/stores/authStore';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Typography from '@material-ui/core/Typography';

import Button from '~ui/Button';
import ConfirmTrigger from '~ui/ConfirmTrigger';
import Grid from '~ui/Grid';
import { SuccessMessage } from '~ui/Message';
import TextInput from '~ui/TextInput';

import ListError from '~/components/ListError';

const TelegramIsAlreadyConnected = ({ username }: { username: string }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleDeactivateTelegram = async () => {
        setErrors([]);
        setLoading(true);

        try {
            await userApi.deactivateTelegram();
            await authStore.pullCurrentUserApi();
        } catch (errors) {
            console.log('errors', errors);
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <SuccessMessage header="Telegram подключен">
                {username !== '-' && `Ваш username: @${username}`}
                {username === '-' && `@username не задан`}
            </SuccessMessage>
            <ListError errors={errors} />

            <ConfirmTrigger
                trigger={
                    <Button loading={loading} color="secondary">
                        Отключить
                    </Button>
                }
                content={'Вам больше не будут приходить уведомления в Telegram'}
                header={'Отключить Telegram от CRM?'}
                confirmButton="Отключить"
                onConfirm={handleDeactivateTelegram}
            />
        </>
    );
};

const TelegramPasscode = ({ onBack }: { onBack: VoidFunction }) => {
    const [passcode, setPasscode] = useState('');
    const handlePasscode = (event, { value }: { value: string }) => {
        setPasscode(value);
    };

    const [checking, setChecking] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const code = passcode.replace(/\D/i, '');
        setPasscode(code);

        if (code.length === 6) {
            setChecking(true);
            setErrors([]);
            userApi
                .checkTelegramCode(code)
                .then(async () => {
                    await authStore.pullCurrentUserApi();
                })
                .catch(setErrors)
                .finally(() => {
                    setPasscode('');
                    setChecking(false);
                });
        }
    }, [passcode]);

    return (
        <Grid.Column>
            <b>Шаг 2.</b> Введите проверочный код, который вам пришел в Telegram после нажатия кнопки /Start (Старт)
            <TextInput
                disabled={checking}
                label="Проверочный код"
                size="medium"
                value={passcode}
                onChange={handlePasscode}
                loading={checking}
                variant="classic"
                autoComplete="off"
            />
            <Button startIcon={<ArrowBackIcon />} size="small" onClick={onBack}>
                назад
            </Button>
            <ListError errors={errors} />
        </Grid.Column>
    );
};

const TelegramConnectInfo = ({ onNext }: { onNext: () => void }) => {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                Как подключить уведомления в Telegram (2 шага)
            </Typography>

            <Typography variant="body1" gutterBottom>
                <b>Шаг 1.</b> Найдите бота&nbsp;
                <b>
                    <a href="https://t.me/agentbase_bot" target="_blank" rel="noreferrer">
                        @agentbase_bot
                    </a>
                </b>
                &nbsp;в приложение Telegram и нажмите кнопку&nbsp;
                <b>СТАРТ (START)</b> (или напишите "START", если кнопки нет)
            </Typography>

            <Button variant="text" primary onClick={onNext}>
                Далее
            </Button>
        </>
    );
};

const TelegramConnectTab = () => {
    const [step, setStep] = useState(0);

    if (authStore?.currentUser?.telegramUsername) {
        return <TelegramIsAlreadyConnected username={authStore.currentUser.telegramUsername} />;
    }
    if (step === 0) {
        return <TelegramConnectInfo onNext={() => setStep(1)} />;
    }

    return (
        <Grid alignItems="flex-end" justify="center" stackable>
            <Grid.Column>
                <TelegramPasscode onBack={() => setStep(0)} />
            </Grid.Column>
        </Grid>
    );
};

export default observer(TelegramConnectTab);
