import cs from 'classnames';
import React, { ReactNode, CSSProperties, ChangeEvent, SyntheticEvent, useMemo } from 'react';

import ClearIcon from '@material-ui/icons/Clear';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MUSelect from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { HandleChangeType } from '../EditField';
import Progress from './Progress';

export type DropdownType = {
    key?: number | string;
    value: number | string | boolean | null;
    text: string;
};

type ChipSelectProps = {
    label?: string | ReactNode;
    value: any[];
    name?: string;
    onChange: HandleChangeType;
    options: SelectMenuType[];
    disabled?: boolean;
    loading?: boolean;
};

export const ChipSelect = ({ options, value, label, name, onChange, disabled, loading }: ChipSelectProps) => {
    const handleChange = (event: ChangeEvent, values: DropdownType[]) => {
        onChange(event, { name, value: values.map(val => (typeof val === 'object' && 'value' in val ? val.value : val)) });
    };

    return (
        <Box position="relative">
            <Autocomplete
                multiple
                options={options.filter(op => !value.includes(op.value))}
                getOptionLabel={option => option.text}
                renderTags={(tagValues, getTagProps) =>
                    tagValues.map((value, index) => {
                        const text = options.find(op => op.value === value)?.text;
                        return <Chip size="small" key={text} label={text} {...getTagProps({ index })} />;
                    })
                }
                value={value}
                renderInput={params => <TextField {...params} size="small" fullWidth variant="standard" label={label} />}
                onChange={handleChange}
                disabled={disabled}
            />
            <Progress linear show={loading} size={24} />
        </Box>
    );
};

export type SelectMenuType = DropdownType & {
    style?: CSSProperties;
};

const useStyles = makeStyles(() => ({
    withoutBorder: {
        '&::before': {
            display: 'none'
        }
    },
    clearBtn: {
        position: 'absolute',
        bottom: '0px',
        right: '35px',
        top: 'calc(50% - 12px)'
    },
    variantClassic: {
        top: 'calc(50% - 22px)'
    }
}));

const SelectClearBtn = ({ onClick, variant }: { onClick: (event: SyntheticEvent) => void; variant?: 'classic' }) => {
    const classes = useStyles();

    return (
        <div className={cs({ [classes.clearBtn]: true, [classes.variantClassic]: variant === 'classic' })}>
            <Tooltip title="Очистить поле">
                <IconButton edge="end" color="inherit" onClick={onClick}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </div>
    );
};

type SelectProps = {
    label?: string | ReactNode;
    value: any;
    name?: string;
    onChange: HandleChangeType;
    options: SelectMenuType[];
    loading?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    fullWidth?: boolean;
    withoutBorder?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    variant?: 'classic';
    size?: 'small' | 'medium';
    inline?: boolean;
};

const Select = ({
    label,
    onChange,
    value,
    options,
    name,
    disabled,
    loading,
    fullWidth,
    style,
    withoutBorder,
    clearable,
    multiple,
    variant,
    size,
    inline
}: SelectProps) => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event, { type: '', name: name || '', value: event.target.value });
    };

    const handleReset = (event: SyntheticEvent) => {
        onChange(event, { type: '', name: name || '', value: multiple ? [] : null });
    };

    const styleSelect: CSSProperties | undefined = useMemo(() => {
        const styleSelect: CSSProperties = style || {};
        if (clearable) {
            styleSelect['paddingRight'] = '2rem';
        }
        if (inline) {
            styleSelect['paddingLeft'] = '0.25rem';
            styleSelect['paddingRight'] = '0.25rem';
        }
        return Object.keys(styleSelect).length > 0 ? styleSelect : undefined;
    }, [clearable, style, inline]);

    return (
        <FormControl disabled={disabled} fullWidth={fullWidth} size={size}>
            {label && (
                <InputLabel
                    style={
                        variant === 'classic'
                            ? {
                                  background: 'white',
                                  paddingLeft: '0.25rem',
                                  paddingRight: '0.25rem',
                                  marginLeft: '0.5rem',
                                  top: '-0.5rem',
                                  zIndex: 1
                              }
                            : undefined
                    }
                >
                    {label}
                </InputLabel>
            )}
            <MUSelect
                value={value ?? ''}
                onChange={handleChange}
                className={cs({ [classes.withoutBorder]: withoutBorder })}
                style={styleSelect}
                variant={variant === 'classic' ? 'outlined' : variant}
                multiple={multiple}
            >
                {options.map(({ text, key, value, style }) => (
                    <MenuItem key={key || text} value={value as any} style={style}>
                        {text}
                    </MenuItem>
                ))}
            </MUSelect>
            {clearable && Boolean(value) && <SelectClearBtn onClick={handleReset} variant={variant} />}

            {loading && <Progress show size={24} />}
        </FormControl>
    );
};

export default Select;
