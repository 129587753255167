import React, { useState, Fragment, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import callSourceStore from '~/stores/cell/callSourceStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CallSourceModal from './CallSourceModal';

import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import ContainerHeader from '~ui/ContainerHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';

const CallSources = () => {
    const [showSourceId, setShowSourceId] = useState<number | null>(null);

    useEffect(() => {
        callSourceStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = callSourceStore;

    return (
        <>
            <ContainerHeader
                handleCreate={() => setShowSourceId(CREATING_ITEM_ID)}
                title="Источники звонков"
                enableCreating={authStore.canCreate(callSourceStore.moduleName)}
                icon={<PhonelinkSetupIcon fontSize="large" />}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ source_id, title, color }, index) => (
                        <ListItem button key={source_id} onClick={() => setShowSourceId(source_id)}>
                            <ListItemText
                                primary={
                                    <>
                                        <Icon name="database" color={color} /> {title}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {showSourceId !== null && <CallSourceModal item_id={showSourceId} handleClose={() => setShowSourceId(null)} />}
        </>
    );
};

export default observer(CallSources);
