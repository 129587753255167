import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import ExportTableTariffs from './ExportTableTariffs';

import ListError from '~/components/ListError';
import Button from '~ui/Button';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import Box from '@material-ui/core/Box';
import { SuccessMessage } from '~ui/Message';

const TabExportTariffs = ({ export_base_id }: { export_base_id: number }) => {
    useMemo(() => {
        exportingBaseStore.exportingBaseTariffsStatistics(export_base_id);
    }, []);
    const [done, setDone] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const { errors, loadingItem } = exportingBaseStore.getItem(export_base_id);

    const handleSave = async () => {
        setDone(false);
        setSaving(true);
        if (await exportingBaseStore.saveItem(export_base_id)) {
            setDone(true);
        }
        setSaving(false);
    };

    return (
        <>
            {done && <SuccessMessage>Настройки сохранены</SuccessMessage>}

            <ExportTableTariffs export_base_id={export_base_id} />

            <Box bgcolor="#f5f6fa" marginTop={2}>
                <ListError errors={errors} />
                <Box display="flex" justifyContent="space-evenly" p={1} bgcolor="#f5f6fa">
                    <Button loading={isSaving} size="medium" primary variant="contained" onClick={handleSave}>
                        Сохранить
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default observer(TabExportTariffs);
