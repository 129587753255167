import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { EXPORT_REPORT_STATUS_ERROR, EXPORT_REPORT_STATUS_GOOD, EXPORT_REPORT_STATUS_WARNING } from '~/types/exports.types';

import commonStore from '~/stores/commonStore';
import exportReportsStore from '~/stores/export/exportReportsStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import DescriptionIcon from '@material-ui/icons/Description';

import ToggleButton from '@material-ui/lab/ToggleButton';

import Button from '~ui/Button';
import { StyledToggleButtonGroup, PultDivider, Pult } from '~ui/FilterPult';
import Select from '~ui/Select';

import ExportReportsDropModal from './ExportReportsDropModal';

const ExportReportsFilter = () => {
    const { isMobile } = commonStore;

    const [showReportModal, setReportModal] = useState(false);
    const toggleReportModal = () => {
        setReportModal(!showReportModal);
    };

    const handleStatus = (event, status) => {
        exportReportsStore.changeFilter('status', status);
    };

    const handleExportBaseIds = (event, { value }: { value: number[] }) => {
        exportReportsStore.changeFilter('export_base_id', value);
    };

    const { loadingList, exportingBasesDropdown } = exportingBaseStore;
    const { status, export_base_id } = exportReportsStore.filter;

    return (
        <Pult>
            <Select
                options={exportingBasesDropdown}
                name="showExportedBases"
                value={export_base_id}
                onChange={handleExportBaseIds}
                multiple
                label="Базы"
                loading={loadingList}
                variant="classic"
                size="small"
                clearable
                style={{ minWidth: '180px' }}
            />
            <PultDivider />
            <StyledToggleButtonGroup size="small" value={status} onChange={handleStatus}>
                <ToggleButton value={EXPORT_REPORT_STATUS_ERROR}>Ошибки</ToggleButton>
                <ToggleButton value={EXPORT_REPORT_STATUS_WARNING}>Предупреждения</ToggleButton>
                <ToggleButton value={EXPORT_REPORT_STATUS_GOOD}>Без ошибок</ToggleButton>
            </StyledToggleButtonGroup>
            <PultDivider />

            {!isMobile && (
                <Button startIcon={<DescriptionIcon />} variant="outlined" color="secondary" onClick={toggleReportModal}>
                    Загрузить отчет
                </Button>
            )}
            {showReportModal && <ExportReportsDropModal open onClose={toggleReportModal} />}
        </Pult>
    );
};

export default observer(ExportReportsFilter);
