import { observer } from 'mobx-react';
import React from 'react';
import Board from 'react-trello';

import commonStore from '~/stores/commonStore';
import kanbanStore, { initialBoardList } from '~/stores/kanbanStore';
import { ContactTableItem } from '~/types/contacts.types';

import BoardLineHeader from './BoardLineHeader';
import ContactBoardCard, { ContactBoardCardProps } from './ContactBoardCard';

async function paginateContactLine(requestedPage: number, listId: string) {
    kanbanStore.loadContactsListNexPage(Number(listId));
}

const printContactCards = (contact: ContactTableItem, index: number): ContactBoardCardProps & { id: number } => {
    const {
        major_users,
        contact_id,
        companyName,
        contactType,
        comments,
        updateTime,
        createTime,
        phones,
        lastCallTime,
        firstName,
        lastName,
        middleName,
        tags_ids,
        estates
    } = contact;

    return {
        id: contact_id,
        contact_id,
        lastCallTime,
        contact: { companyName, contactType, firstName, lastName, middleName, contact_id },
        updateTime,
        comments,
        tags_ids
    };
};

const handleDragEnd = (contact_id: number, lineId1: string, lineId2: string, pos: number) => {
    kanbanStore.updateContactListId(contact_id, Number(lineId1), Number(lineId2));
};

const ContactsBoard = ({ board_id }: { board_id: number }) => {
    const { isMobile } = commonStore;
    const boardLists = [initialBoardList, ...kanbanStore.getBoardLists(board_id)];

    const data = {
        lanes: boardLists.map(({ textColor, bgColor, title, list_id }) => {
            const found = kanbanStore.boardListContacts.get(list_id);
            const { loading, count, items, errors } = found || { loading: true, items: [], count: 0, errors: [], pageNumber: 0 };

            return {
                id: String(list_id),
                loading: Boolean(loading),
                title,
                errors,
                label: loading && !count ? '...' : count.toLocaleString(),
                style: {
                    backgroundColor: bgColor,
                    color: textColor,
                    minHeight: 70,
                    maxHeight: `calc(100vh - ${isMobile ? '160' : '137'}px)`
                },
                cards: items.map((contact, index) => printContactCards(contact, index))
            };
        })
    };

    return (
        <Board
            data={data}
            laneSortFunction={(card1, card2) => parseInt(card2.updateTime) - parseInt(card1.updateTime)}
            onLaneScroll={paginateContactLine}
            style={{ backgroundColor: '#f5f6fa', height: `calc(100vh - ${isMobile ? '140' : '117'}px)` }}
            handleDragEnd={handleDragEnd}
            components={{ LaneHeader: BoardLineHeader, Card: ContactBoardCard }}
        />
    );
};

export default observer(ContactsBoard);
