// Изменяем импорты
import initializeAddressMap from '~/common/osmMapApi';

import React from 'react';

import commonStore from '~/stores/commonStore';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import Button from '~ui/Button';
import { Modal, ModalContent, useToggle } from '~ui/Modal';

// или LocationOnIcon

const YandexMapId = 'yandexmap';

const AddressOnMapBtn = ({ geo_lat, geo_lon, address }: { geo_lat: number; geo_lon: number; address: string }) => {
    const [showModal, toggleModal] = useToggle();

    const showMap = () => {
        initializeAddressMap(YandexMapId, Number(geo_lat), Number(geo_lon), address);
    };

    return (
        <>
            <Button startIcon={<LocationOnIcon />} variant="outlined" size="small" onClick={toggleModal}>
                на карте
            </Button>
            {showModal && (
                <Modal onMount={showMap} fullScreen={commonStore.isMobile} header="Объект на карте" maxWidth="md" onClose={toggleModal}>
                    <ModalContent dividers>
                        <div id={YandexMapId} className="crm-Estate__mapBox" />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default AddressOnMapBtn;
