import React, { Fragment } from 'react';

import { Contact } from '~/types/contacts.types';
import { UserLinkType } from '~/types/users.types';

import { EXPIRED_CALL_STATUS_WARNING } from '~/api/cell/expiredCallsApi';

import settingStore from '~/stores/settingStore';

import InfoIcon from '@material-ui/icons/Info';

import Box from '@material-ui/core/Box';

import Button from '~ui/Button';
import Message, { MessageTitle } from '~ui/Message';

import ContactLinkWithPreview from '~/components/Lists/Contacts/ContactLinkWithPreview';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';

type MessageExpireProps = {
    basesCount: number;
    major_users: UserLinkType[] | null;
    contact: Contact;
    handleHelpModal: () => void;
    expireStatus: 3 | 2 | 1;
};

const MessageExpire = ({ basesCount, major_users, contact, handleHelpModal, expireStatus }: MessageExpireProps) => {
    const config = settingStore.mainConfig;
    const { lastOutgoingCallTimesWarning, lastOutgoingCallTimesBan } = config;

    return (
        <Message severity={expireStatus === EXPIRED_CALL_STATUS_WARNING ? 'warning' : basesCount === 0 ? 'warning' : 'error'}>
            <MessageTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    <div>Нет звонков (сообщений) Продавцу (Посреднику)</div>
                    <Button onClick={handleHelpModal} size="small" variant="outlined" color="secondary" startIcon={<InfoIcon />}>
                        Подсказка
                    </Button>
                </Box>
            </MessageTitle>
            <p>
                Не найдено звонков или сообщений в WhatsApp между ответственным агентом&nbsp;
                {major_users && <UsersLinkList usersList={major_users} />} и продавцом объекта&nbsp;
                {contact && <ContactLinkWithPreview contact={contact} />} за последние&nbsp;
                {expireStatus === EXPIRED_CALL_STATUS_WARNING ? lastOutgoingCallTimesWarning : lastOutgoingCallTimesBan} дней.&nbsp;
                {basesCount > 0 && <Fragment>Скоро экспорт будет недоступен.</Fragment>}
                {expireStatus === EXPIRED_CALL_STATUS_WARNING && basesCount === 0 && <Fragment>Экспорт включить невозможно.</Fragment>}
            </p>
        </Message>
    );
};

export default MessageExpire;
