import React, { useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { checkFormValid } from '~/common/forms';
import useConstructor from '~/common/useConstructor';

import { CallSource } from '~/types/cellCalls.types';
import commonStore from '~/stores/commonStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import callSourceStore from '~/stores/cell/callSourceStore';

import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import StorageIcon from '@material-ui/icons/Storage';

import EditField from '~/components/Base/EditField';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ListError from '~/components/ListError';
import { COLORS_DROPDOWN } from '~/components/Settings/Tags/TagModal';

type CallSourceModalProps = HandlerEditingWrappedProps & { handleClose: () => void };

const CallSourceModal = (props: CallSourceModalProps) => {
    const $form = useRef<HTMLFormElement | null>(null);

    useConstructor(() => {
        const { item_id } = props;

        callSourceStore.fetchItem(item_id);

        if (item_id === CREATING_ITEM_ID) {
            const callSource: Partial<CallSource> = {
                source_id: item_id,
                title: '',
                description: '',
                color: 'red',
                enable: true
            };

            callSourceStore.setEditingItem(item_id, callSource);
        }
    });

    const handleCreateSource = async (event: React.SyntheticEvent) => {
        if (checkFormValid($form.current, true)) {
            event.preventDefault();
            event.stopPropagation();

            const { item_id, handleClose } = props;

            if (item_id === CREATING_ITEM_ID) {
                await callSourceStore.createItem();
                const { errors } = callSourceStore.getItem(item_id);
                if (errors && errors.length) {
                    return;
                }
            } else {
                await callSourceStore.saveItem(item_id);
            }

            handleClose();
            callSourceStore.fetchList();
        }
    };

    const handleDelete = async () => {
        const { item_id, handleClose } = props;
        callSourceStore.setEditingItem(item_id, {
            enable: false
        });
        await callSourceStore.saveItem(item_id);

        handleClose();

        callSourceStore.fetchList();
    };

    const { handleClose, item_id, handleChange, handleTextAreaChange } = props;
    const { editingItem, loadingItem, errors } = callSourceStore.getItem(item_id);

    const { title, description, color } = editingItem || {};

    const modalActions: ModalBtnAction[] = [
        { onClick: handleClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
        {
            onClick: handleCreateSource,
            label: item_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            disabled: loadingItem,
            color: 'primary',
            variant: 'outlined',
            startIcon: <SaveIcon />
        }
    ];

    if (item_id > CREATING_ITEM_ID) {
        modalActions.splice(1, 0, {
            onClick: handleDelete,
            label: 'Удалить',
            disabled: loadingItem,
            variant: 'text',
            color: 'secondary',
            startIcon: <DeleteIcon />
        });
    }

    return (
        <Modal
            maxWidth="sm"
            onClose={handleClose}
            actions={modalActions}
            fullScreen={commonStore.isMobile}
            loading={loadingItem}
            header={
                <>
                    <StorageIcon style={{ color }} /> &nbsp; Источник звонков
                </>
            }
        >
            <ModalContent>
                <ListError errors={Array.from(errors || [])} />

                {!loadingItem && editingItem && (
                    <form ref={$el => ($form.current = $el)}>
                        <div className="ui form small">
                            <div className="crm-Item__editingMode crm-Triggers__modalField">
                                <EditField.Text
                                    label="Название"
                                    placeholder="Тэг"
                                    value={title}
                                    name="title"
                                    onChange={handleChange}
                                    required=".{3,}"
                                />

                                <EditField.Drop label="Цвет" name="color" value={color} onChange={handleChange} options={COLORS_DROPDOWN} />

                                <EditField.Area
                                    label="Описание источника"
                                    value={description}
                                    name="description"
                                    onChange={handleTextAreaChange}
                                />
                            </div>
                        </div>
                    </form>
                )}
            </ModalContent>
        </Modal>
    );
};

export default HandlerEditingBlockWrapper(callSourceStore, observer(CallSourceModal));
