import { useCallback } from 'react';
import ListStorePrototype from '~/stores/prototypes/ListStore.prototype';
import { TItemExtended } from '~/stores/prototypes/ItemStore.prototype';

const useFilterChange = <
    ItemObjectType extends TItemExtended,
    FilterKey extends keyof ItemListFilter,
    ItemListType = ItemObjectType,
    ItemPropertyType = NonNullable<unknown>,
    ItemListFilter = NonNullable<unknown>
>(
    store: ListStorePrototype<ItemObjectType, ItemListType, ItemPropertyType, ItemListFilter>,
    filterKey: FilterKey,
    adjustValue?: (value: ItemListFilter[FilterKey]) => ItemListFilter[FilterKey]
): [ItemListFilter[FilterKey], (value: ItemListFilter[FilterKey] | { value: ItemListFilter[FilterKey] }) => void] => {
    const callback = useCallback(
        (data: ItemListFilter[FilterKey] | { value: ItemListFilter[FilterKey] }) => {
            const value = data && typeof data === 'object' && 'value' in data ? data.value : data;
            // @ts-ignore
            store.changeFilter(filterKey, adjustValue ? adjustValue(value) : value);
        },
        [filterKey, store]
    );
    return [store.listFilter[filterKey], callback];
};

export default useFilterChange;
