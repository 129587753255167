import useConstructor from '~/common/useConstructor';
import history from '~/history';

import React, { Fragment } from 'react';

import { observer } from 'mobx-react';

import { Card } from 'semantic-ui-react';

import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import { ExportedEstate } from '~/types/exports.types';

import authStore from '~/stores/authStore';
import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';

import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import Skeleton from '@material-ui/lab/Skeleton';

import EditButton from '~/components/Base/EditButton';
import InfoPopup from '~/components/Base/InfoPopup';
import { EditingWrappedProps } from '~/components/Items/EditingBlockWrapper';
import ExpiredCallMessage from '~/components/Items/Estate/SubBlocks/ExpiredCalls/ExpiredCallMessage';
import ListError from '~/components/ListError';
import { DealLinkState } from '~/components/Lists/Deals/DealLink';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';

import ExportBlockShowBaseItem from './ExportBlockShowBaseItem';

type ExportBlockShowProps = EditingWrappedProps & {
    extendMode?: boolean;
    item_id: number;
};

export const openEstateExportEditionState = (estate_id: number, switchFromTab: boolean) => ({
    pathname: `/estate/sell/crm/${estate_id}/export`,
    state: { modal: true, switchFromTab, editing: true }
});

const ExportBlockShow = (props: ExportBlockShowProps) => {
    useConstructor(() => {
        exportStore.fetchItem(props.item_id);
    });

    const { item_id, extendMode } = props;
    const estate = estateStore.getItem(item_id).item;
    if (!estate) {
        return null;
    }
    let { exclusiveDealId, dealId, major_user_ids } = estate;

    const {
        item,
        property: { exportingBaseNames },
        errors,
        loadingItem
    } = exportStore.getItem(item_id);

    let bases: ExportedEstate[] = [];
    let totalCost = 0;
    let major_users = null;

    if (item) {
        bases = item.bases;
        totalCost = item.totalCost;
        major_users = item.major_users;
        major_user_ids = [...major_user_ids, ...item.major_user_ids];
    }

    const handleExclusive = () => {
        history.push(DealLinkState(Number(exclusiveDealId), ESTATE_TYPE_SELL));
    };

    const handleDeal = () => {
        history.push(DealLinkState(Number(dealId), ESTATE_TYPE_SELL));
    };

    const handleEdit = () => {
        history.push(openEstateExportEditionState(item_id, true));
    };

    const canEdit = authStore.canEdit(exportStore.moduleName, major_user_ids);

    return (
        <Card fluid color={bases && bases.length === 0 ? 'red' : 'yellow'} className={`crm-Item crm-FluentButtons`}>
            {canEdit && !extendMode && <EditButton onClick={handleEdit} />}

            <Card.Content>
                <ExpiredCallMessage item_id={item_id} />
                {errors && <ListError errors={errors} />}

                <Card.Header>
                    Экспорт объекта&nbsp;
                    {Number(exclusiveDealId) > 0 && (
                        <Chip
                            label="эксклюзив"
                            style={{ backgroundColor: '#21ba45', color: 'white' }}
                            title="Открыть договор"
                            onClick={handleExclusive}
                        />
                    )}
                    {!exclusiveDealId && Number(dealId) > 0 && (
                        <Chip label={`сделка №${dealId}`} title="Открыть договор" onClick={handleDeal} />
                    )}
                </Card.Header>
                {!loadingItem && (
                    <Card.Meta>
                        Потрачено
                        <InfoPopup
                            size="tiny"
                            content="Расход на рекламу этого объекта всеми агентами, кто брал его в работу, за все время.
                                Если вы взяли объект в работу, то расход других агентов на вас не переводится."
                        />
                        &nbsp;
                        {totalCost || 0}₽
                    </Card.Meta>
                )}

                <Card.Description>
                    {!extendMode && exportingBaseNames && exportingBaseNames.join(', ')}
                    {extendMode && (
                        <List dense disablePadding>
                            {bases.map(({ export_base_id, price_id, publishTime }) => {
                                return (
                                    <ExportBlockShowBaseItem
                                        key={export_base_id}
                                        export_base_id={export_base_id}
                                        estate_id={item_id}
                                        price_id={price_id}
                                        publishTime={publishTime}
                                    />
                                );
                            })}
                        </List>
                    )}
                    {!loadingItem && bases && bases.length === 0 && <b>Не экспортируется</b>}
                    {loadingItem && (
                        <Fragment>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                        </Fragment>
                    )}
                </Card.Description>
            </Card.Content>
            {major_users && major_users.length > 0 && (
                <Card.Content extra>
                    <UsersLinkList avatar usersList={major_users} />
                </Card.Content>
            )}
        </Card>
    );
};

export default observer(ExportBlockShow);
