import { CallRate, CallRatesFilter } from '~/types/callRates.types';
import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQUserMainFields } from '~/api/userApi';
import { GQCallsMainFields } from '~/api/cell/cellCallsApi';
import { GQMainContactFields } from '~/api/contactsApi';

const GQCallRateFields = `
    call_rate_id
    call_id
    
    category_id
    rate
    comment
    positiveFeedback
    improvementSuggestions
    
    major_user_id
    user {
        ${GQUserMainFields}
    }
    caller {
        ${GQUserMainFields}
    }
    
    call {
        ${GQCallsMainFields}
    }
    contact {
        ${GQMainContactFields}
    }
    
    categoryOptionsUsed {
        category_option_id
        value
    }
    
    createTime
    updateTime
    enable
`;

export const fetchItem = async (call_rate_id: number): Promise<CallRate> => {
    const graphql = `{
        fetchCallRate(call_rate_id: ${call_rate_id}) {
            ${GQCallRateFields}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchCallRate: CallRate }>(graphql);
    return data.fetchCallRate;
};

export const createItem = async (callRate: CallRate): Promise<number> => {
    const graphql = `mutation { 
          createCallRate(callRateInput: ${objectToGraphql(callRate)})
        }`;

    const data = await fetchApi.postGQ<{ createCallRate: number }>(graphql);
    return data.createCallRate;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    callRateFilter: CallRatesFilter,
    controller?: AbortController
): Promise<{ list: CallRate[]; count: number }> => {
    const graphql = `{
        fetchCallRates(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
        sort: "${orderDirection}", filter: ${objectToGraphql(callRateFilter)}) {
            list {${GQCallRateFields}}
            count
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchCallRates: { list: CallRate[]; count: number } }>(graphql, controller);
    return data.fetchCallRates;
};

export const fetchCallRatesByCallId = async (call_id: number, controller?: AbortController): Promise<CallRate[]> => {
    const graphql = `{
        fetchCallRatesByCallId(call_id: ${call_id}) {
            ${GQCallRateFields}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchCallRatesByCallId: CallRate[] }>(graphql, controller);
    return data.fetchCallRatesByCallId;
};

export const saveItem = async (call_rate_id: number, callRate: Partial<CallRate>): Promise<number> => {
    const graphql = `mutation { 
        updateCallRate(call_rate_id: ${call_rate_id}, callRateDiff: ${objectToGraphql(callRate)})
    }`;

    const data = await fetchApi.postGQ<{ updateCallRate: number }>(graphql);
    return data.updateCallRate;
};
