import React from 'react';
import { observer } from 'mobx-react';

import callRateStore from '~/stores/cell/callRateStore';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

type RateCallCategoryItemOptionProps = {
    call_rate_id: number;
    category_id: number;
    category_option_id: number;
    title: string;
};

const RateCallCategoryItemOption = ({ call_rate_id, category_id, category_option_id, title }: RateCallCategoryItemOptionProps) => {
    const { categoryOptionsUsed } = callRateStore.getItem(call_rate_id).editingItem;

    const value = categoryOptionsUsed.find(cou => cou.category_option_id === category_option_id)?.value || null;

    const handleChange = (_, value) => {
        const optionExist = categoryOptionsUsed.find(cou => cou.category_option_id === category_option_id);
        if (optionExist) {
            if (value) {
                optionExist.value = value;
            } else {
                categoryOptionsUsed.splice(categoryOptionsUsed.indexOf(optionExist), 1);
            }
        } else if (value) {
            categoryOptionsUsed.push({ category_option_id, value });
        }
    };

    const handleToggle = () => {
        handleChange(null, value === 1 ? 2 : value === 2 ? null : 1);
    };

    return (
        <ListItem key={category_option_id} button disableRipple>
            <ListItemIcon>
                <ToggleButtonGroup exclusive size="small" value={value} style={{ maxHeight: '1.5rem' }} onChange={handleChange}>
                    <ToggleButton
                        value={1}
                        style={value === 1 ? { background: 'darkgreen', color: 'white', fontWeight: 'bold' } : undefined}
                    >
                        +
                    </ToggleButton>
                    <ToggleButton value={2} style={value === 2 ? { background: '#d12600', color: 'white', fontWeight: 'bold' } : undefined}>
                        -
                    </ToggleButton>
                </ToggleButtonGroup>
            </ListItemIcon>
            <ListItemText primary={title} title={title} style={{ cursor: 'pointer', userSelect: 'none' }} onClick={handleToggle} />
        </ListItem>
    );
};

export default observer(RateCallCategoryItemOption);
