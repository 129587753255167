import React, { Fragment } from 'react';

import { observer } from 'mobx-react';

import { EVENT_TYPE_CALL } from '~/types/events.types';

import { EXPIRED_CALL_STATUS_ERROR, EXPIRED_CALL_STATUS_WARNING, matchExpiredCallStatus } from '~/api/cell/expiredCallsApi';

import authStore from '~/stores/authStore';
import expiredCallStore from '~/stores/cell/expiredCallStore';
import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import Box from '@material-ui/core/Box';

import Button from '~ui/Button';
import { useToggle } from '~ui/Modal';

import EventModal from '~/components/CalendarEvents/EventModal';
import ExpiredCallModal from '~/components/Items/Estate/SubBlocks/ExpiredCalls/ExpiredCallModal';

import ExpiredCallHelpModal from './ExpiredCallHelpModal';
import MessageExpire from './MessageExpire';

type ExpiredCallMessageProps = {
    item_id: number;
};

const ExpiredCallMessage = (props: ExpiredCallMessageProps) => {
    const { item_id } = props;

    const [showExpiredCallModal, toggleExpiredCallModal] = useToggle();
    const [showHelpModal, handleToggleHelpModal] = useToggle();
    const [showCallEventModal, toggleCallEventModal] = useToggle();

    const handleToggleCallEventModal = () => {
        handleToggleHelpModal();
        toggleCallEventModal();
    };

    const { major_user_ids, lastOutgoingCallToOwnerTime, contact_id, isFake } = estateStore.getItem(item_id).item || {};
    const { contact } = estateStore.getItem(item_id).property;

    const { item } = exportStore.getItem(item_id);

    let major_users = null;
    let bases = [];

    if (item) {
        major_users = item.major_users;
        bases = item.bases;
    }

    const expireStatus = matchExpiredCallStatus(lastOutgoingCallToOwnerTime || 0, isFake);
    if (![EXPIRED_CALL_STATUS_ERROR, EXPIRED_CALL_STATUS_WARNING].includes(expireStatus)) {
        return null;
    }

    return (
        <Fragment>
            <MessageExpire
                basesCount={bases.length}
                contact={contact}
                major_users={major_users}
                handleHelpModal={handleToggleHelpModal}
                expireStatus={expireStatus}
            />

            {authStore.canEdit(expiredCallStore.moduleName, major_user_ids) && (
                <Box paddingBottom="2rem">
                    <Button size="small" color="secondary" variant="outlined" onClick={toggleExpiredCallModal}>
                        Сбросить ограничение
                    </Button>
                </Box>
            )}

            {showExpiredCallModal && (
                <ExpiredCallModal item_id={CREATING_ITEM_ID} estate_id={item_id} handleClose={toggleExpiredCallModal} />
            )}

            {showHelpModal && (
                <ExpiredCallHelpModal
                    item_id={item_id}
                    onClose={handleToggleHelpModal}
                    onClickCallEventModal={handleToggleCallEventModal}
                />
            )}

            {showCallEventModal && (
                <EventModal
                    onClose={handleToggleCallEventModal}
                    item_id={CREATING_ITEM_ID}
                    contact_id={contact_id}
                    eventType={EVENT_TYPE_CALL}
                />
            )}
        </Fragment>
    );
};

export default observer(ExpiredCallMessage);
