import React, { ChangeEvent } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { HandleChangeType } from '~/components/Base/EditField';

const InputMinMax = ({
    minName,
    maxName,
    minValue,
    maxValue,
    title,
    onChange,
    min,
    max,
    disabledMin,
    disabledMax,
    smartValue,
    adornment
}: {
    minName: string;
    maxName: string;
    minValue: number | null;
    maxValue: number | null;
    disabledMin?: boolean;
    disabledMax?: boolean;
    title: string;
    onChange: HandleChangeType;
    min: number;
    max: number;
    smartValue?: boolean;
    adornment?: 'rub' | 'square' | string;
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        onChange(event, { type: 'text', name: event.target.name, value: Number(event.target.value.replace(/\D/g, '')) });
    };

    const endAdornment = adornment ? (
        <InputAdornment position="end">{adornment === 'rub' ? 'ք' : adornment === 'square' ? 'м²' : adornment}</InputAdornment>
    ) : undefined;

    return (
        <Grid container spacing={2} justify="space-around" alignItems="center">
            <Grid item xs={6}>
                <TextField
                    type={smartValue ? 'text' : 'number'}
                    label={`${title}, от`}
                    placeholder={`${title}, от`}
                    variant="outlined"
                    inputProps={{ min, max, name: minName }}
                    value={minValue ? (smartValue ? minValue.toLocaleString() : minValue) : ''}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                        endAdornment
                    }}
                    size="small"
                    disabled={disabledMin}
                    style={disabledMin ? { background: '#f3f3f3' } : undefined}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type={smartValue ? 'text' : 'number'}
                    label={`${title}, до`}
                    placeholder={`${title}, до`}
                    error={minValue && maxValue && maxValue < minValue}
                    variant="outlined"
                    inputProps={{ min, max, name: maxName }}
                    value={maxValue ? (smartValue ? maxValue.toLocaleString() : maxValue) : ''}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                        endAdornment
                    }}
                    size="small"
                    disabled={disabledMax}
                    style={disabledMax ? { background: '#f3f3f3' } : undefined}
                />
            </Grid>
        </Grid>
    );
};

export default InputMinMax;
