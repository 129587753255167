import React from 'react';

import Box from '@material-ui/core/Box';

import CallSources from './CallSources';
import CallRateCategories from './CallRateCategories';

const CallSettings = () => {
    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="1rem">
            <Box maxWidth="500px">
                <CallSources />
            </Box>
            <Box maxWidth="500px">
                <CallRateCategories />
            </Box>
        </Box>
    );
};

export default CallSettings;
