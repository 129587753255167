import React from 'react';

import { observer } from 'mobx-react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import StorageIcon from '@material-ui/icons/Storage';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import config from '~/config/ui.config';

import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';
import commonStore from '~/stores/commonStore';
import { calcExportPriceByEstateCost } from '~/stores/helpers/export.store.helpers';

import InfoPopup from '~/components/Base/InfoPopup';

import PrintExportExternalStatus from '../EditBlocks/SubBlocks/PrintExportExternalStatus';

import { DAY_MS, execDaily } from '~/common/time';

type ExportBlockShowBaseItemProps = {
    export_base_id: number;
    estate_id: number;
    price_id: number;
    publishTime: number;
};

const calcNextPaymentTime = (publishTime: number, minDays: number) => {
    const currentDay = new Date();
    currentDay.setHours(0, 0, 0, 0);

    const publishDate = new Date(publishTime * 1000);
    const deltaDays = Math.abs(Math.ceil((currentDay.getTime() - publishDate.getTime()) / DAY_MS));
    let neededDaysToSum = minDays - (deltaDays % minDays);

    return Math.floor((currentDay.getTime() + neededDaysToSum * DAY_MS) / 1000);
};

const ExportBlockShowBaseItem = ({ export_base_id, estate_id, price_id, publishTime }: ExportBlockShowBaseItemProps) => {
    const estate = estateStore.getItem(estate_id).item;
    if (!estate) {
        return null;
    }
    let { type, propertyType } = estate;
    const externalStatuses = exportStore.getItem(estate_id).item.externalStatuses || [];

    const exportingBases = exportStore.filterTariffs(type, propertyType, estate);

    const foundBase = exportingBases.find(base => base.export_base_id === export_base_id);
    if (!foundBase) return null;

    const { name, image, tariff, regionName } = foundBase;

    const price = tariff.prices.find(price => price.price_id === price_id);
    if (!price) return null;

    const foundStatus = externalStatuses.find(externalStatus => externalStatus.export_base_id === export_base_id);
    const externalStatus = foundStatus ? foundStatus.externalStatus : null;

    const { printTime, deltaTime } = execDaily(calcNextPaymentTime(publishTime, price.minDays) * 1000);

    const { isMobile } = commonStore;

    return (
        <ListItem dense disableGutters={isMobile} alignItems={isMobile ? 'flex-start' : undefined}>
            <ListItemIcon>
                {image && <Avatar src={image ? `${config.publicUrl}${image}` : undefined} alt={name} title={name} />}
                {!image && <StorageIcon />}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Box display="flex" style={{ gap: '0.5rem' }}>
                        <b>{name}</b>
                        {externalStatus && <PrintExportExternalStatus export_base_id={export_base_id} externalStatus={externalStatus} />}
                    </Box>
                }
                secondary={
                    <Box display="flex" alignItems="center" flexDirection="row" style={{ gap: '0.5rem' }}>
                        <div>{price && price.name}</div>
                        {regionName && (
                            <Box display="flex" alignItems="center" flexDirection="row">
                                ({regionName} <InfoPopup content="Тариф согласно указанному региону" size="tiny" />)
                            </Box>
                        )}
                    </Box>
                }
            />
            {!isMobile && price.dailyPrice > 0 && (
                <ListItemSecondaryAction>
                    <Typography variant="body1">
                        {calcExportPriceByEstateCost(estate, tariff.pricesModeByEstateCost, price).toLocaleString('ru')}
                        <span>
                            ₽ / {price.minDays}&nbsp;{price.minDays === 1 ? 'день' : price.minDays < 5 ? 'дня' : 'дней'}
                            {tariff.pricesModeByEstateCost && (
                                <InfoPopup color="secondary" content="Цена зависит от цены объекта" size="tiny" />
                            )}
                        </span>
                    </Typography>
                    <Typography variant="body2" color={Math.abs(deltaTime) < 2 * DAY_MS ? 'secondary' : 'textSecondary'}>
                        след. списание: {printTime.replace(', 00:00', '')}
                    </Typography>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};

export default observer(ExportBlockShowBaseItem);
