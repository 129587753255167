import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Accordion, Grid, Input, List, Image, Form, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import RichDropdown from '~/components/Base/ui/RichDropdown';
import authStore from '~/stores/authStore';
import userStore from '~/stores/userStore';
import UserAvatar from '~ui/UserAvatar';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import isEqual from '~/common/isEqual';

type UsersAndGroupsDropdownProps = {
    disableGroups?: boolean;
    disableUsers?: boolean;
    onOutsideClick?: (event: SyntheticEvent) => void;
    group_id: number[];
    major_user_id: number[];
    handleChange: HandleChangeType;
    shortWidth?: boolean;
    placeholder?: string;
};

type UsersAndGroupsDropdownState = {
    activeIndex: number;
    searchQuery: string;
};

@observer
class UsersAndGroupsDropdown extends Component<UsersAndGroupsDropdownProps, UsersAndGroupsDropdownState> {
    swappingUserInList = true;

    constructor(props: UsersAndGroupsDropdownProps) {
        super(props);

        this.state = {
            activeIndex: 0,
            searchQuery: ''
        };
    }

    renderLabel = ({
        text,
        value: item_id
    }: {
        text: string;
        value: number;
    }): { color?: SemanticCOLORS; content: string; icon?: SemanticICONS } | null => {
        if (this.props.major_user_id.length) {
            return {
                content: text,
                icon: 'user'
            };
        }
        if (userStore.groups.find(({ group_id }) => group_id === item_id)) {
            return {
                color: 'blue',
                content: text,
                icon: 'building'
            };
        }
        return {
            color: 'green',
            content: text,
            icon: 'group'
        };
    };

    handleOpenClick = (event: SyntheticEvent, { index }: { index: number }) => {
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    };

    triggerChange = (event: SyntheticEvent, major_user_id: number[], group_id: number[]) => {
        if (!isEqual(major_user_id, this.props.major_user_id)) {
            this.props.handleChange(event, { name: 'major_user_id', value: major_user_id });
        }
        if (!isEqual(group_id, this.props.group_id)) {
            this.props.handleChange(event, { name: 'group_id', value: group_id });
        }
    };

    handleMajorUser = (event: SyntheticEvent, user_id: number) => {
        const { group_id, major_user_id } = this.props;
        this.swappingUserInList = true;

        this.triggerChange(event, Array.from(new Set([...major_user_id, user_id])), [...group_id]);
    };

    toggleGroupId = (groupId: number) => {
        const { group_id } = this.props;
        const newGroupIds = [...group_id];
        const isGroupInSet = ~group_id.indexOf(groupId);

        const subgroupsIds = authStore.subgroupsByGroupId[groupId] || [groupId];

        // Убираем все подгруппы
        for (const group_id of subgroupsIds) {
            const foundIndex = newGroupIds.indexOf(groupId);
            if (~foundIndex) {
                newGroupIds.splice(foundIndex, 1);
            }
        }
        // Включаем или убираем основную группу из списка
        if (!isGroupInSet) {
            newGroupIds.push(groupId);
        } else {
            const foundIndex = group_id.indexOf(groupId);
            if (~foundIndex) {
                newGroupIds.splice(foundIndex, 1);
            }
        }
        return newGroupIds;
    };

    handleDropdownRemove = async (event: SyntheticEvent, { value }: { value: number[] }) => {
        const isUseUsers = this.props.major_user_id.length > 0;
        this.triggerChange(event, isUseUsers ? value : [], isUseUsers ? [] : value);
    };

    handleSearchChange = (event: SyntheticEvent, { value: searchQuery }: { value: string }) => {
        this.setState({ searchQuery });
    };

    handleGroupId = async (event: SyntheticEvent, { value: group_id }: { value: number }) => {
        const newGroupIds = this.toggleGroupId(group_id);
        this.swappingUserInList = false;
        this.triggerChange(event, [], newGroupIds);
    };

    filterUserAndGroups = () => {
        const { userAndGroups } = authStore;
        const { searchQuery } = this.state;
        const { group_id, major_user_id } = this.props;

        let newGroupIds = authStore.matchAllSubgroupsIdsByGroupIds(group_id);

        return userAndGroups
            .filter(({ user_id }) => (this.swappingUserInList && major_user_id.length > 0 ? !major_user_id.includes(user_id) : true))
            .filter(({ group_id }) => (newGroupIds.length > 0 ? newGroupIds.includes(group_id) : true))
            .map(({ firstName, lastName, user_id, avatarUrl }) => ({
                text: `${lastName} ${firstName}`.trim(),
                value: user_id,
                key: user_id,
                image: { avatarUrl, firstName, lastName }
            }))
            .filter(({ text }) => (searchQuery ? ~text.toUpperCase().indexOf(searchQuery.toUpperCase()) : true));
    };

    render() {
        const { disableUsers, disableGroups, onOutsideClick, shortWidth, placeholder } = this.props;
        const { activeIndex, searchQuery } = this.state;
        const { group_id, major_user_id } = this.props;
        const { groups } = userStore;

        const userAndGroupsList = this.filterUserAndGroups();
        const isLabelUseUsers = major_user_id.length > 0;

        return (
            <RichDropdown
                placeholder={placeholder || 'Отдел, Сотрудник'}
                value={isLabelUseUsers || disableGroups ? major_user_id : group_id}
                options={isLabelUseUsers ? authStore.userAndGroupsDropdown : userStore.getGroupsDropdown}
                onChange={this.handleDropdownRemove}
                renderLabel={this.renderLabel}
                onClick={onOutsideClick}
                shortWidth={shortWidth}
            >
                <Grid
                    stackable
                    className={cs({ 'crm-List__usersGroups': true, 'crm-List__usersGroups_short': disableUsers || disableGroups })}
                >
                    {!disableGroups && (
                        <Grid.Column width={disableUsers ? 16 : 9}>
                            <Accordion className="crm-List__usersGroups_groups">
                                {groups.map(({ name, group_id: currentGrupId, subgroups }, index) => {
                                    const allOfficeHasChecked = group_id.includes(currentGrupId);

                                    return (
                                        <Fragment key={currentGrupId}>
                                            <Accordion.Title
                                                active={activeIndex === index}
                                                content={<label className="crm-List__usersGroups_label">{name}</label>}
                                                index={index}
                                                onClick={this.handleOpenClick}
                                            />
                                            <Accordion.Content
                                                active={activeIndex === index}
                                                content={
                                                    <Form>
                                                        <Form.Group grouped>
                                                            <Form.Checkbox
                                                                indeterminate={!allOfficeHasChecked}
                                                                key={currentGrupId}
                                                                checked={allOfficeHasChecked}
                                                                label="Весь офис"
                                                                name="group_id"
                                                                value={currentGrupId}
                                                                onChange={this.handleGroupId}
                                                            />
                                                            {subgroups.map(({ name, group_id: subGroupId }) => (
                                                                <Form.Checkbox
                                                                    key={subGroupId}
                                                                    checked={allOfficeHasChecked || group_id.includes(subGroupId)}
                                                                    label={name}
                                                                    name="group_id"
                                                                    value={subGroupId}
                                                                    onChange={this.handleGroupId}
                                                                    disabled={allOfficeHasChecked}
                                                                />
                                                            ))}
                                                        </Form.Group>
                                                    </Form>
                                                }
                                            />
                                        </Fragment>
                                    );
                                })}
                            </Accordion>
                        </Grid.Column>
                    )}
                    {!disableUsers && (
                        <Grid.Column width={disableGroups ? 16 : 7}>
                            <Input
                                icon="search"
                                placeholder="Поиск"
                                size="mini"
                                fluid
                                value={searchQuery}
                                onChange={this.handleSearchChange}
                            />
                            <List selection verticalAlign="middle" size="tiny" className="crm-List__usersGroups_userList">
                                {userAndGroupsList.map(({ text, value, key, image }) => (
                                    <List.Item
                                        key={String(key)}
                                        onClick={(event: SyntheticEvent) => this.handleMajorUser(event, Number(key))}
                                    >
                                        {image && (
                                            <Image avatar>
                                                <UserAvatar
                                                    size={30}
                                                    src={image.avatarUrl}
                                                    firstName={image.firstName}
                                                    lastName={image.lastName}
                                                />
                                            </Image>
                                        )}
                                        <List.Content>
                                            <List.Description>{text}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                ))}
                            </List>
                        </Grid.Column>
                    )}
                </Grid>
            </RichDropdown>
        );
    }
}

export default UsersAndGroupsDropdown;
