import React, { useState, useCallback } from 'react';

import { sendTestTelegramMessage } from '~/api/userApi';

import Button from '~ui/Button';
import { SuccessMessage } from '~ui/Message';

import ListError from '~/components/ListError';

const SendTelegramTestMessageBtn = ({ user_id }: { user_id: number }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [done, setDone] = useState(false);

    const handleSendTelegramMessage = useCallback(async () => {
        try {
            setLoading(true);
            await sendTestTelegramMessage(user_id);
            setDone(true);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Неизвестная ошибка');
            }
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <>
            <Button
                loading={loading}
                tooltip="Отправить тестовое сообщение сотруднику"
                variant="outlined"
                size="small"
                onClick={handleSendTelegramMessage}
            >
                Тестовое сообщение
            </Button>
            {error && <ListError errors={[error]} />}
            {done && <SuccessMessage>Сообщение отправлено</SuccessMessage>}
        </>
    );
};

export default SendTelegramTestMessageBtn;
