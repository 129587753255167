import React, { SyntheticEvent } from 'react';

import { observer } from 'mobx-react';

import { EstateFilter } from '~/types/estate.types';

import estateStore from '~/stores/estateStore';

import Select from '~ui/Select';

import { deltaCreateTimes } from '../../ListFilterWrapper';

const DEFAULT_NO_CALLS_TIME = 7 * 86400;

export const deltaNoCallsTimes = [
    { key: 0, value: 0, text: 'Все время' },
    { key: 1, value: DEFAULT_NO_CALLS_TIME, text: '7 дней' },
    { key: 2, value: 10 * 86400, text: '10 дней' },
    { key: 3, value: 14 * 86400, text: '14 дней' },
    { key: 4, value: 30 * 86400, text: '30 дней' }
];

const FilterEstateDeltaTime = () => {
    const handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: keyof EstateFilter; value: string }) => {
        estateStore.changeFilter(name, value);
    };

    const { deltaTime } = estateStore.listFilter;

    return <Select size="small" options={deltaCreateTimes} value={deltaTime} name="deltaTime" onChange={handleChange} inline />;
};

export default observer(FilterEstateDeltaTime);
