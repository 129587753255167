import React from 'react';
import { observer } from 'mobx-react';

import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { HandleChangeType } from '~/components/Base/EditField';

import { TableCell, TableBody } from '~ui/Table';
import InputMinMax from '~ui/InputMinMax';
import Button from '~ui/Button';

import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import ExportPriceCostTableCell from './ExportPriceCostTableCell';

type ExportTariffPriceByCostTableBodyProps = {
    tariff_id: number;
    export_base_id: number;
};

const ExportTariffPriceByCostTableBody = ({ tariff_id, export_base_id }: ExportTariffPriceByCostTableBodyProps) => {
    const { prices } = exportingBaseStore.getItem(export_base_id).editingItem.tariffs.find(tariff => tariff.tariff_id === tariff_id);

    const pricesByName = Object.groupBy(prices, ({ name }) => name);

    const handleAdd = (): void => {
        prices.forEach(price => {
            const { estateCosts, price_id } = price;
            const lastCost = estateCosts.at(-1);
            lastCost.maxEstateCost = lastCost.minEstateCost;

            price.estateCosts = [
                ...estateCosts.slice(0, estateCosts.length - 1),
                { ...lastCost, maxEstateCost: lastCost.maxEstateCost || 0 },
                { minEstateCost: lastCost.maxEstateCost || 0, maxEstateCost: null, price: lastCost.price, price_id, price_cost_id: 0 }
            ];
        });
    };

    const handleChangeCost = (index: number): HandleChangeType => (_, { name, value }): void => {
        prices.forEach(price => {
            const { estateCosts } = price;

            const nextCost = estateCosts[index + 1];
            if (nextCost) {
                nextCost.minEstateCost = value;
            }

            const cost = estateCosts[index];
            cost[name] = value;
            price.estateCosts = [...estateCosts.slice(0, index), cost, ...estateCosts.slice(index + 1)];
        });
    };

    const handleRemove = (index: number): void => {
        prices.forEach(price => {
            const { estateCosts } = price;
            price.estateCosts = estateCosts
                .filter((_, i) => i !== index)
                .map((cost, i, arr) => {
                    if (i > index - 1) {
                        cost.maxEstateCost = arr[i - 1].maxEstateCost;
                    }
                    if (i === arr.length - 1) {
                        cost.maxEstateCost = null;
                    }
                    return cost;
                });
        });
    };

    return (
        <TableBody>
            {prices[0].estateCosts.map((estateCost, index) => {
                const { minEstateCost, maxEstateCost } = estateCost;

                return (
                    <TableRow key={`index-${index}`}>
                        <TableCell>
                            <InputMinMax
                                smartValue={true}
                                minName="minEstateCost"
                                minValue={minEstateCost}
                                maxName="maxEstateCost"
                                maxValue={maxEstateCost}
                                title="Цена"
                                onChange={handleChangeCost(index)}
                                // min={uniquePrices.at(index - 1)?.maxEstateCost || 1}
                                min={0}
                                max={999999999}
                                adornment="rub"
                                disabledMin
                                disabledMax={maxEstateCost === null}
                            />
                        </TableCell>

                        {Object.keys(pricesByName).map(name => (
                            <ExportPriceCostTableCell
                                key={name}
                                export_base_id={export_base_id}
                                tariff_id={tariff_id}
                                name={name}
                                maxEstateCost={maxEstateCost}
                                minEstateCost={minEstateCost}
                            />
                        ))}

                        <TableCell>
                            {index < prices[0].estateCosts.length - 1 && (
                                <Button
                                    icon={<RemoveCircleOutline color="secondary" />}
                                    tooltip="Убрать цену"
                                    onClick={() => handleRemove(index)}
                                />
                            )}
                            {index === prices[0].estateCosts.length - 1 && (
                                <Button icon={<AddCircleOutlineIcon color="primary" />} tooltip="Добавить цену" onClick={handleAdd} />
                            )}
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

export default observer(ExportTariffPriceByCostTableBody);
