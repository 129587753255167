import estateL10n from '~/L10n/estate.L10n';

import React, { Component, ChangeEvent } from 'react';

import { observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import kanbanStore from '~/stores/kanbanStore';
import tagStore from '~/stores/tagStore';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import Checkbox, { Switch } from '~ui/Checkbox';
import Select, { ChipSelect } from '~ui/Select';

import InfoPopup from '~/components/Base/InfoPopup';
import FilterCell from '~/components/Lists/Common/FilterCell';
import { DEFAULT_NO_CALLS_TIME } from '~/components/Lists/Estate/FilterBlocks/FilterEstateOtherParams';
import { FilterMoreDetailsProps } from '~/components/Lists/ListFilterWrapper';

const deltaNoCallsTimes = [
    { key: 1, value: DEFAULT_NO_CALLS_TIME, text: '7 дней' },
    { key: 2, value: 10 * 86400, text: '10 дней' },
    { key: 3, value: 14 * 86400, text: '14 дней' },
    { key: 4, value: 30 * 86400, text: '30 дней' }
];

@observer
class FilterContactsMoreDetails extends Component<FilterMoreDetailsProps, {}> {
    handleTagsIds = (event: React.SyntheticEvent, { value }: { value: number[] }) => {
        kanbanStore.changeFilter('tags_ids', value);
    };

    handleNoCalls = (event: ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const oldValue = kanbanStore.listFilter[name];
        // @ts-ignore
        kanbanStore.changeFilter(name, oldValue === null ? DEFAULT_NO_CALLS_TIME : null);
    };

    render() {
        const { tags_ids, withoutAgent, tagsTogether, hasCallEvent, noOutgoingCallsTime } = kanbanStore.listFilter;
        const { allTagsDropdown, loadingFetchAllTags } = tagStore;
        const { handleChange, handleCheckbox } = this.props;

        return (
            <div>
                <Dropdown
                    multiple
                    selection
                    options={allTagsDropdown}
                    search
                    placeholder="Тэги контактов"
                    value={tags_ids}
                    noResultsMessage="Тэг не найден"
                    onChange={this.handleTagsIds}
                    loading={loadingFetchAllTags}
                />

                <FormControlLabel
                    control={<Checkbox checked={tagsTogether} onChange={handleCheckbox} name="tagsTogether" />}
                    label="тэги вместе"
                    style={{ marginLeft: '1em' }}
                />

                <FilterCell title="Звонки" titleFullWidth spacing={0}>
                    <Grid item xs={12}>
                        <Switch
                            checked={noOutgoingCallsTime !== null}
                            name="noOutgoingCallsTime"
                            label="Нет звонков (сообщений)"
                            onChange={this.handleNoCalls}
                        />
                        {typeof noOutgoingCallsTime === 'number' && (
                            <div className="crm-Estate__filter_exportedBases">
                                <Box display="flex" alignItems="center">
                                    <div>за &nbsp;</div>
                                    <Select
                                        options={deltaNoCallsTimes}
                                        name="noOutgoingCallsTime"
                                        value={noOutgoingCallsTime}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </div>
                        )}
                        <InfoPopup
                            size="small"
                            content={`Поиск только среди тех контактов, с которыми был хотя бы один звонок/переписка. 
                                      Учитываются состоявшиеся входящие или исходящие звонки, сообщения в WhatsApp на/c номер(а) телефона контакта, который указан в карточке объекта.`}
                        />
                    </Grid>
                </FilterCell>

                <FilterCell title="Установки" titleFullWidth spacing={0} hideDivider>
                    <Grid item xs={12}>
                        <Checkbox checked={hasCallEvent} onChange={handleCheckbox} name="hasCallEvent" label="Запланирован звонок" />
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox checked={withoutAgent} onChange={handleCheckbox} name="withoutAgent" label="Пустые" />
                    </Grid>
                </FilterCell>
            </div>
        );
    }
}

export default FilterContactsMoreDetails;
