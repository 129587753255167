import React from 'react';
import { Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Table, { TableHead } from '~ui/Table';

import callRateStore from '~/stores/cell/callRateStore';

import ListFooter from '~/components/Lists/Common/ListFooterNew';
import ListWrapper, { ListWrapperProps } from '~/components/Lists/ListWrapper';
import ListError from '~/components/ListError';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';

import FilterCallRates from './FilterCallRates';
import CallRatesTableBody from './CallRatesTableBody';
import CallRatesTableHeaders from './CallRatesTableHeaders';

const CallRatesList = (props: ListWrapperProps) => {
    const { orderBy, orderDirection, handleSort } = props;
    const { loadingList, list, listCount, nextListLoaded, pagination, listErrors } = callRateStore;

    return (
        <Container maxWidth="lg">
            <div className="crm-List">
                <Box display="flex" alignItems="center">
                    <ImportantDevicesIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />
                    <Typography variant="h5" style={{ paddingRight: '0.5rem' }}>
                        Оценки звонков
                    </Typography>
                </Box>

                {listErrors && <ListError errors={listErrors} />}
            </div>

            <Segment.Group className="crm-Segment" id="crm_ListTop">
                <FilterCallRates />

                <Segment className="crm-List__segment">
                    <Table size="small">
                        <TableHead>
                            <ListPagination
                                loading={loadingList}
                                colSpan={5}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={callRateStore.pageChange}
                                pageSizeChange={callRateStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                            />
                        </TableHead>

                        <CallRatesTableHeaders orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                        <CallRatesTableBody callRatesList={list} />

                        <ListFooter
                            loading={loadingList}
                            colSpan={5}
                            pagination={pagination}
                            listCount={listCount}
                            pageChange={callRateStore.pageChange}
                            pageSizeChange={callRateStore.pageSizeChange}
                            nextListLoaded={nextListLoaded}
                            scrollToElementId="crm_ListTop"
                        />
                    </Table>
                </Segment>
            </Segment.Group>
        </Container>
    );
};

export default ListWrapper(callRateStore, observer(CallRatesList));
