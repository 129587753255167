import history from '~/history';

import cs from 'classnames';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { observer } from 'mobx-react';

import { EstateExportStatus } from '~/types/exportReports.types';
import { EXPORT_REPORT_STATUS_ERROR, EXPORT_REPORT_STATUS_WARNING } from '~/types/exports.types';

import exportReportsStore from '~/stores/export/exportReportsStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import ApartmentIcon from '@material-ui/icons/Apartment';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, makeStyles } from '@material-ui/core/styles';

import Progress from '~ui/Progress';

import Scrollbar from '~/components/Base/Scrollbar';
import Time from '~/components/Base/Time';
import { openEstateExportEditionState } from '~/components/Items/Estate/ExportBlocks/ExportBlockShow';
import ListError from '~/components/ListError';
import EstateLink from '~/components/Lists/Estate/EstateLink';

const useStylesItem = makeStyles(({ palette }: Theme) => ({
    error: {
        color: palette.error.dark
    },
    warning: {
        color: palette.warning.dark
    }
}));

const EstateExportErrorItem = React.memo(({ export_base_id, status, estate_id, estate, reportTime, message }: EstateExportStatus) => {
    const classes = useStylesItem();

    return (
        <ListItem
            button
            onClick={() => history.push(openEstateExportEditionState(estate_id, false))}
            alignItems="flex-start"
            dense
            disableGutters
        >
            <ListItemAvatar>
                <>
                    {estate.avatarUrl && <Avatar src={estate.avatarUrl} />}
                    {!estate.avatarUrl && <ApartmentIcon fontSize="large" />}
                </>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <>
                        <Typography variant="subtitle2" style={{ display: 'inline' }}>
                            {exportingBaseStore.getBaseName(export_base_id)}
                        </Typography>{' '}
                        <EstateLink estate={estate} />
                    </>
                }
                secondary={
                    <>
                        <Time time={reportTime} />
                        {' — '}
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            style={{ display: 'inline' }}
                            className={cs({
                                [classes.error]: status === EXPORT_REPORT_STATUS_ERROR,
                                [classes.warning]: status === EXPORT_REPORT_STATUS_WARNING
                            })}
                        >
                            {message}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    );
});

const useStylesList = makeStyles(({ spacing }: Theme) => ({
    paper: {
        padding: spacing(1)
    },
    scrollbar: {
        maxHeight: 'calc(100vh - 160px)',
        minHeight: '150px'
    }
}));

const ExportReportsTable = () => {
    const classes = useStylesList();

    useMemo(() => {
        exportReportsStore.fetchExportErrorsList();
    }, []);
    const { exportReportErrorsList, hasMore, currentPage, loadingErrors } = exportReportsStore;

    return (
        <Paper className={classes.paper}>
            <ListError errors={loadingErrors} />
            <Scrollbar className={classes.scrollbar}>
                <InfiniteScroll
                    pageStart={currentPage}
                    initialLoad={false}
                    loadMore={exportReportsStore.loadNextPage}
                    hasMore={hasMore}
                    loader={
                        <div key="loading" style={{ position: 'relative' }}>
                            <Progress show />
                        </div>
                    }
                    useWindow={false}
                >
                    <List dense disablePadding>
                        {exportReportErrorsList.map(item => (
                            <EstateExportErrorItem key={item.report_estate_id} {...item} />
                        ))}
                    </List>
                </InfiniteScroll>
            </Scrollbar>
        </Paper>
    );
};

export default observer(ExportReportsTable);
