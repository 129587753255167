import { objectToGraphql } from '~/common/graphql';

import { HistoryChange } from '~/types/historyChanges.types';
import { User, UserGroup, UserFilter, Phone, UserBalanceInfo } from '~/types/users.types';

import { GQHistoryBaseChange } from '~/api/historyChangesApi';

import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

import fetchApi from '../common/fetchApi';
import config from '../config/ui.config';
import { GQAddressFields } from './newbuildingsApi';

export const GQUserMainFields = `
    firstName
    lastName
    user_id
    avatarUrl
    enable
`;

export const GQHistoryChange = `
    ${GQHistoryBaseChange}
    major_user {
        ${GQUserMainFields}
    }     
`;

const GQUserSeoFields = config.enableUsersSEO
    ? `
    promoDescription
    isSiteProfileShow
    siteStatus
    siteLink
`
    : ``;

const GQGroupFields = `
    group_id
    name
    region_id
    chief_id
    chief {
        ${GQUserMainFields}
    }
`;

const GQUserTableFields = `
    ${GQUserMainFields}
    appointment
    sex
    
    group_id
    group {
        ${GQGroupFields}  
    }
    
    phones {
        phone_id
        phone
    }
    
    dailyOutgoingCalls
    weekOutgoingCalls
    
    createTime
    updateTime
    dismissalTime
    recruitmentTime
    onlineTime
`;

const GQUserFields = `
    ${GQUserTableFields}
    access_id
    access {
        access_id
        title
        modules {
          name
          access {
            read
            write
            delete
          }
        }
        enableApplyingPremium
        maxEnableDebt
        export_moderator_id
        chiefIsModerator
    }
    middleName
    email
    birthday
    photoUrl
    avatarUrl
    balance
    documentsCount
    estatesCount
    innerPhones
    hiddenDescription
    
    
    main_metro_id
    userAddress {
        ${GQAddressFields}
    }
    
    grant_users_ids
    grantUsersIds {
        ${GQUserMainFields}
    }
    grant_users_under_ids
    grantUsersUnderIds {
        ${GQUserMainFields}
    }
    
    icsCalendarKey
    whatsappIsEnable
    telegramUsername
`;

export const GQGroupsFields = `
    group_id
    name
    usersCount
    parent_id
    chief {
      ${GQUserMainFields}
    }
    region_id
    enable
`;

export const pullCurrentUser = async (): Promise<User> => {
    const graphql = `{
            fetchUser {
                ${GQUserFields}
            } 
          }`;

    const data: { fetchUser: User } = await fetchApi.getGQ(graphql);
    return data.fetchUser;
};

export const fetchItem = async (id: number): Promise<User> => {
    const graphql = `{
            fetchUser(user_id: ${id}) {
                ${GQUserFields}
                ${GQUserSeoFields}
            } 
          }`;

    const data: { fetchUser: User } = await fetchApi.getGQ(graphql);
    return data.fetchUser;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    userFilter: UserFilter,
    controller?: AbortController
): Promise<{ list: Array<User>; count: number }> => {
    const graphql = `{
            fetchUsers(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(userFilter)}) {
                list {${GQUserTableFields}}
                count
            } 
          }`;

    const data: { fetchUsers: { list: Array<User>; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchUsers;
};

export const saveItem = async (user_id: number, user: User): Promise<number> => {
    const { photo, ...inputUser } = user;

    const graphql = `mutation { 
          updateUser(user_id: ${user_id}, user: ${objectToGraphql(inputUser)})
        }`;

    const data: { updateUser: number } = await fetchApi.postGQ(graphql);
    return data.updateUser;
};

export const saveContacts = async (
    user_id: number,
    contacts: any
): Promise<{ email: string; phones: Array<Phone>; innerPhones?: string[] }> => {
    const graphql = `mutation { 
          saveItemContacts(type: USER, item_id: ${user_id}, contacts: ${objectToGraphql(contacts)}) {
                email,
                phones {
                    phone_id
                    phone
                }
                innerPhones
              }
        }`;

    const data: { saveItemContacts: { email: string; phones: Array<Phone>; innerPhones?: string[] } } = await fetchApi.postGQ(graphql);
    return data.saveItemContacts;
};

export const loadAvatar = async (
    user_id: number,
    imagebase64: string,
    avatar_imagebase64: string
): Promise<{ photoUrl: string; avatarUrl: string }> => {
    const graphql = `mutation { 
          loadAvatar(user_id: ${user_id}, imagebase64: "${imagebase64}", avatar_imagebase64: "${avatar_imagebase64}") {
            photoUrl
            avatarUrl 
          }
        }`;

    const data: { loadAvatar: { photoUrl: string; avatarUrl: string } } = await fetchApi.postGQ(graphql);
    return data.loadAvatar;
};

let GROUPS_CACHE: UserGroup[] | null = null;

export const fetchGroups = async (): Promise<UserGroup[]> => {
    if (!GROUPS_CACHE) {
        const graphql = `{
        fetchGroups { 
            ${GQGroupsFields}
            subgroups {
                ${GQGroupsFields}
            }
        } 
      }`;

        const data: { fetchGroups: UserGroup[] } = await fetchApi.getGQ(graphql);
        GROUPS_CACHE = data.fetchGroups;
    }
    return GROUPS_CACHE;
};

export const resetGroupsCache = () => {
    GROUPS_CACHE = null;
};

/* eslint-disable */
export const findGroupById = (groups: UserGroup[], group_id: number): UserGroup | null => {
    let foundSubGroup: UserGroup | null = null;
    const found = groups.some(group => {
        if (group.group_id === group_id) {
            foundSubGroup = group;
            return true;
        }
        group.subgroups.find(subgroup => {
            if (subgroup.group_id === group_id) {
                foundSubGroup = subgroup;
                return true;
            }
        });
        if (foundSubGroup) {
            return true;
        }
    });
    return found ? foundSubGroup : null;
};
/* eslint-enable */

export const createItem = async (userInput: User): Promise<number> => {
    const graphql = `mutation { 
          createUser(userInput: ${objectToGraphql(userInput)})
        }`;

    const data: { createUser: number } = await fetchApi.postGQ(graphql);
    return data.createUser;
};

export const resetBalance = async (user_id: number, fromTime: number | null): Promise<number> => {
    const graphql = `mutation { 
              resetBalance(user_id: ${user_id}, fromTime: ${fromTime ? fromTime : 'null'})
            }`;

    const data: { resetBalance: number } = await fetchApi.postGQ(graphql);
    return data.resetBalance;
};

export const resetUserPayments = async (user_id: number): Promise<number> => {
    const graphql = `mutation { 
              resetUserPayments(user_id: ${user_id})
            }`;

    const data = await fetchApi.postGQ<{ resetUserPayments: number }>(graphql);
    return data.resetUserPayments;
};

export const resetPassword = async (user_id: number): Promise<number> => {
    const graphql = `mutation { 
          resetPassword(user_id: ${user_id})
        }`;

    const data: { resetPassword: number } = await fetchApi.postGQ(graphql);
    return data.resetPassword;
};

export const fetchItemHistory = async (user_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchUserChangeHistory(user_id: ${user_id}) {
            ${GQHistoryChange}   
        } 
      }`;

    const data: { fetchUserChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchUserChangeHistory;
};

export const fetchUserBalance = async (user_id: number, fromTime: number): Promise<number> => {
    const graphql = `{
        fetchUserBalance(user_id: ${user_id}, fromTime: ${fromTime})
      }`;

    const data: { fetchUserBalance: number } = await fetchApi.getGQ(graphql);
    return data.fetchUserBalance;
};

export const getUserBalanceInfo = async (user_id: number): Promise<UserBalanceInfo> => {
    const graphql = `{
        getUserBalanceInfo(user_id: ${user_id}) {
            lastRedeemTime
            paymentsSum 
            transactionsSum   
            balance
        }
      }`;

    const data: { getUserBalanceInfo: UserBalanceInfo } = await fetchApi.getGQ(graphql);
    return data.getUserBalanceInfo;
};

export const editGroup = async (group_id: number, inputGroup: Partial<UserGroup>): Promise<number> => {
    const graphql = `mutation { 
          editGroup(group_id: ${group_id}, inputGroup: ${objectToGraphql(inputGroup)})
        }`;

    const data: { editGroup: number } = await fetchApi.postGQ(graphql);
    return data.editGroup;
};

export const requestTelegramUsernameCode = async (username: string): Promise<boolean> => {
    const graphql = `mutation { 
          requestTelegramUsernameCode(username: "${username}")
        }`;

    const data = await fetchApi.postGQ<{ requestTelegramUsernameCode: boolean }>(graphql);
    return data.requestTelegramUsernameCode;
};

export const checkTelegramCode = async (passCode: string): Promise<boolean> => {
    const graphql = `mutation { 
          checkTelegramCode(passCode: "${passCode}")
        }`;

    const data = await fetchApi.postGQ<{ checkTelegramCode: boolean }>(graphql);
    return data.checkTelegramCode;
};

export const deactivateTelegram = async (): Promise<boolean> => {
    const graphql = `mutation { 
          deactivateTelegram
        }`;

    const data = await fetchApi.postGQ<{ deactivateTelegram: boolean }>(graphql);
    return data.deactivateTelegram;
};

export const addBalancePayment = async (user_id: number, payment: number): Promise<number> => {
    const graphql = `mutation { 
          addBalancePayment(user_id: ${user_id}, payment: ${payment})
        }`;

    const data = await fetchApi.postGQ<{ addBalancePayment: number }>(graphql);
    return data.addBalancePayment;
};

export type TelegramMessage = {
    message_id: number;
    direction: 'in' | 'out';
    message: string;
    createTime: number;
};

export const fetchUserTelegramMessages = async (user_id: number): Promise<TelegramMessage[]> => {
    const graphql = `{
        fetchUserTelegramMessages(user_id: ${user_id}) {
            message_id
            direction 
            message   
            createTime
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchUserTelegramMessages: TelegramMessage[] }>(graphql);
    return data.fetchUserTelegramMessages;
};

export const sendTestTelegramMessage = async (user_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          sendTestTelegramMessage(user_id: ${user_id})
        }`;

    const data = await fetchApi.postGQ<{ sendTestTelegramMessage: boolean }>(graphql);
    return data.sendTestTelegramMessage;
};
