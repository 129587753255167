import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import Button from '~ui/Button';
import Modal, { ModalContent } from '~ui/Modal';

type ExpireCallHelpModalProps = {
    item_id: number;
    onClose: VoidFunction;
    onClickCallEventModal: VoidFunction;
};

const ExpireCallHelpModal = (props: ExpireCallHelpModalProps) => {
    return (
        <Modal header="Нет подходящих тем для звонка?" onClose={props.onClose}>
            <ModalContent>
                <Typography variant="h6">Возможные темы разговора с Продавцом (Посредником)</Typography>

                <ul style={{ lineHeight: '1.75rem' }}>
                    <li>
                        Попробуйте подыскать подходящих покупателей по&nbsp;
                        <Link
                            title="Открыть вкладку с потенциальными Покупателями"
                            to={{
                                pathname: `/estate/sell/crm/${props.item_id}/wishes`,
                                state: { modal: true, switchFromTab: true }
                            }}
                        >
                            базе Покупателей
                        </Link>
                        &nbsp; и договориться о показе. С предложением потенциального показа Продавцу (Посреднику) звонить лучше всего.
                    </li>
                    <li>
                        Если вы считаете, что цена данного объекта завышена, вы можете позвонить Продавцу (Посреднику) и:
                        <ul>
                            <li>Обсудить статистику просмотров объявления</li>
                            <li>Рассказать о похожих объявлениях в CRM с историей снижения цены</li>
                            <li>Предложить посмотреть похожие объекты</li>
                            <li>Обсудить возможность (варианты) торга</li>
                        </ul>
                    </li>
                    <li>Предложить заключить Эксклюзив, что даст возможность быстрее продать объект</li>
                    <li>Предложить альтернативную сделку/обмен на новостройку</li>
                    <li>Уточнить актуальную стоимость объекта, были ли у него предложения аванса и по какой цене?</li>
                    <li>Предложить вывесить баннер</li>
                    <li>Предложить профессиональную фотосессию/видеоролик</li>
                    <li>Предложить аукционный метод продаж</li>
                    <li>
                        Предложить временное размещение с заниженной стоимостью
                        <ul>
                            <li>Предложить выставить дешевле, чтобы понять рынок (выставить - не значит продать)</li>
                            <li>Лучше получить предложение и отказаться от него, нежели не получить его вовсе</li>
                        </ul>
                    </li>
                </ul>
                <Button size="small" color="primary" variant="contained" onClick={props.onClickCallEventModal}>
                    Запланировать звонок
                </Button>
            </ModalContent>
        </Modal>
    );
};

export default ExpireCallHelpModal;
