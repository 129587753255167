import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import ContainerHeader from '~ui/ContainerHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';

import CallRateCategoryModal from './CallRateCategoryModal';

const CallRateCategories = () => {
    const [itemId, setItemId] = useState<number | null>(null);

    useEffect(() => {
        callRateCategoryStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = callRateCategoryStore;

    return (
        <>
            <ContainerHeader
                handleCreate={() => setItemId(CREATING_ITEM_ID)}
                title="Критерии оценки звонков"
                enableCreating={authStore.canCreate(callRateCategoryStore.moduleName)}
                icon={<ImportantDevicesIcon fontSize="large" />}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ category_id, title }, index) => (
                        <ListItem button key={category_id} onClick={() => setItemId(category_id)}>
                            <ListItemText
                                primary={
                                    <>
                                        <Icon name="street view" /> {title}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {itemId !== null && <CallRateCategoryModal item_id={itemId} handleClose={() => setItemId(null)} />}
        </>
    );
};

export default observer(CallRateCategories);
