import React from 'react';

import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';
import kpiStore from '~/stores/kpiStore';
import statisticStore from '~/stores/statisticStore';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Modal, { ModalContent } from '~ui/Modal';

import TableStatistics from '~/components/Analytics/TableStatistics';
import UsersAndGroupsDropdown from '~/components/Lists/Common/UsersAndGroupsDropdown';

type StatisticsModalProps = {
    onClose: (event: React.SyntheticEvent) => void;
};

const StatisticsModal = (props: StatisticsModalProps) => {
    const { mainTableKpi } = kpiStore;

    return (
        <Modal
            header={
                <Box display="flex" alignItems="center" flexDirection="row" style={{ gap: '1rem' }}>
                    <Typography variant="h5">Сводная таблица показателей</Typography>
                    {!commonStore.isMobile && (
                        <div>
                            {!mainTableKpi && <UsersAndGroupsDropdown store={statisticStore} disableUsers />}
                            {mainTableKpi && <UsersAndGroupsDropdown disableUsers store={kpiStore} />}
                        </div>
                    )}
                </Box>
            }
            onClose={props.onClose}
            fullWidth={commonStore.isMobile}
            maxWidth="xl"
        >
            <ModalContent>
                <TableStatistics />
            </ModalContent>
        </Modal>
    );
};

export default observer(StatisticsModal);
