import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';

import wishStore from '~/stores/wishStore';

import { Menu, Label } from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';

import Button from '@material-ui/core/Button';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import TocIcon from '@material-ui/icons/Toc';
import IconButton from '@material-ui/core/IconButton';

import { ESTATE_BASES } from '~/types/estate.types';

import { useToggle } from '~ui/Modal';
import Progress from '~ui/Progress';

import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import ButtonGroup from '~/components/Base/ButtonGroup';
import MapOrderBy from '~/components/Lists/Estate/MapView/MapOrderBy';

import WishMapList from './WishMapList';
import settingStore from '~/stores/settingStore';
import { wishesListMenuOrder } from '~/components/Lists/Wishes';
import WishesMapFilter from './WishesMapFilter';

type EstatePreviewProps = {
    estate_id: number;
    base: ESTATE_BASES;
};

const WishesMapView = () => {
    useEffect(() => {
        return () => {
            wishStore.unmountSearchOnMap();
        };
    }, []);

    const [showFilterOnMap, toggleFilter] = useToggle();

    const handleChangeBase: HandleChangeType = (event, { value }) => {
        wishStore.changeFilterBase(value);
    };

    const {
        listCount,
        loadingCount,
        listFilter: { base, propertyType }
    } = wishStore;

    return (
        <Fragment>
            <div className="crm-Map__estateMap" id="mapSearch">
                <div className="crm-Map__chips">{/*<EstateMapFilterChips type="map" />*/}</div>
            </div>

            <div className="crm-Map__estateMap_list">
                <Menu pointing secondary>
                    <Menu.Item name="list" active onClick={() => {}} color="yellow">
                        Найдено
                        <Label color="teal">{loadingCount ? '...' : listCount}</Label>
                    </Menu.Item>

                    {settingStore?.mainConfig?.enableWishBooking && (
                        <Menu.Item>
                            <div className="form ui mini">
                                <ButtonGroup
                                    buttonSet={wishesListMenuOrder}
                                    name="base"
                                    value={base}
                                    handleChange={handleChangeBase}
                                    stackable
                                />
                            </div>
                        </Menu.Item>
                    )}

                    {!showFilterOnMap && (
                        <Fragment>
                            <Menu.Item>
                                <Button variant="outlined" size="small" startIcon={<TuneIcon />} onClick={toggleFilter}>
                                    Фильтр
                                </Button>
                            </Menu.Item>

                            <Menu.Item position="right">
                                <MapOrderBy onChange={(orderBy, orderDirection) => wishStore.fetchList(orderBy, orderDirection)} />
                                <IconButton size="small" onClick={() => wishStore.toggleSearchOnMap()} title="Перейти в таблицу">
                                    <TocIcon />
                                </IconButton>
                            </Menu.Item>
                        </Fragment>
                    )}

                    {showFilterOnMap && (
                        <Menu.Item position="right">
                            <IconButton size="small" onClick={toggleFilter} title="Закрыть фильтр">
                                <CloseIcon />
                            </IconButton>
                        </Menu.Item>
                    )}
                </Menu>

                {!showFilterOnMap && (
                    <PerfectScrollbar>
                        <InfiniteScroll
                            pageStart={0}
                            initialLoad={false}
                            loadMore={wishStore.showNextOnMap.bind(wishStore)}
                            hasMore={wishStore.availableMoreOnMap}
                            loader={
                                <div key={wishStore.selectedMapIds.length} style={{ padding: '1rem' }}>
                                    <Progress show linear />
                                </div>
                            }
                            useWindow={false}
                        >
                            <div className="crm-Map__estateMap_estates">
                                <WishMapList />
                            </div>
                        </InfiniteScroll>
                    </PerfectScrollbar>
                )}
                {showFilterOnMap && <WishesMapFilter onCloseFilter={toggleFilter} />}
            </div>
        </Fragment>
    );
};

export default observer(WishesMapView);
