import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { CallRate } from '~/types/callRates.types';

import authStore from '~/stores/authStore';
import commonStore from '~/stores/commonStore';
import { CellCallMetadata } from '~/stores/helpers/cellCalls.helpers';
// import useApiFetch from '~/stores/hooks/useApiFetch';
import * as callRatesApi from '~/api/cell/callRatesApi';

import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { Modal, ModalContent, useToggle } from '~ui/Modal';
import Divider from '~ui/Divider';
import Button from '~ui/Button';
import ListError from '~/components/ListError';
import LoaderAwait from '~/components/Base/LoaderAwait';

import ListenOrDownloadBox from './ListenOrDownloadBox';
import CallListensItem from './CallListensItem';

import RateCellCallModal from './RateCellCallModal';

const CallRatesModal = ({ callMetadata, onClose }: { callMetadata: CellCallMetadata; onClose: () => void }) => {
    const [showModal, toggleModal] = useToggle(false);
    // let { data: list, loading, error } = useApiFetch(callRatesApi.fetchCallRatesByCallId(callMetadata.call_id));

    const [list, setData] = useState<CallRate[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        callRatesApi
            .fetchCallRatesByCallId(callMetadata.call_id)
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    const onCloseModal = () => {
        toggleModal();
        setLoading(true);
        setError(null);
        callRatesApi
            .fetchCallRatesByCallId(callMetadata.call_id)
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const hasAlreadyRatedByMyself = list.find(({ major_user_id }) => major_user_id === authStore.currentUserId);

    return (
        <>
            <Modal onClose={!loading ? onClose : null} fullScreen={commonStore.isMobile} fullWidth maxWidth="sm" header="Оценка звонка">
                <ModalContent dividers>
                    <ListenOrDownloadBox callMetadata={callMetadata} showDownloadLink={false} />
                    <Divider />
                    {error && <ListError errors={[error]} />}
                    <LoaderAwait active={loading} linear />
                    {list.length > 0 && (
                        <List>
                            {list.map(callRate => (
                                <CallListensItem key={callRate.call_rate_id} callRate={callRate} />
                            ))}
                        </List>
                    )}
                    {!loading && (
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                disabled={loading}
                                color="secondary"
                                startIcon={hasAlreadyRatedByMyself ? <EditIcon /> : <AddIcon />}
                                size="small"
                                onClick={toggleModal}
                            >
                                {hasAlreadyRatedByMyself ? 'Изменить оценку' : 'Добавить оценку'}
                            </Button>
                        </Box>
                    )}
                </ModalContent>
                {showModal && <RateCellCallModal callMetadata={callMetadata} onClose={onCloseModal} />}
            </Modal>
        </>
    );
};

export default observer(CallRatesModal);
