import { observable, action } from 'mobx';
import * as callRatesApi from '~/api/cell/callRatesApi';

import ListStorePrototype from '~/stores/prototypes/ListStore.prototype';
import { CallRate, CallRateInBase, CallRatesFilter } from '~/types/callRates.types';
import cellCallStore from '~/stores/cell/cellCallStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

class CallRateStore extends ListStorePrototype<CallRate, CallRate, {}, CallRatesFilter> {
    listFilterClear: CallRatesFilter = {
        major_user_id: [],
        caller_user_id: [],
        caller_group_id: [],
        group_id: [],
        category_id: null,
        enable: true
    };

    orderBy = 'call_rate_id';

    constructor() {
        super('call_rate_id', 'cellCall', callRatesApi);
        this.clearFilter();
    }

    // @action
    // async fetchItem(id: number) {
    //     await super.fetchItem(id, null, true);
    //     const sourceItem = this.getItem(id).item || {};
    //     this.setEditingItem(id, deepCopy(sourceItem));
    // }

    validationItem(callEvent: CallRateInBase): string[] {
        const errors: string[] = [];

        // if (!callEvent.rate) {
        //     errors.push('Поставьте оценку');
        // }

        return errors;
    }

    @action
    async createItem(): Promise<number> {
        const { call_id } = this.getItem(CREATING_ITEM_ID).editingItem;
        const item_id = await super.createItem();
        if (item_id) {
            this.fetchList();
            cellCallStore.mergeList(call_id, { hasListened: true });
        }
        return item_id;
    }
}

export default new CallRateStore();
