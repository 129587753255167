import { AVITO_EXPORT_BASE_ID, ExportingTariffPrice, YANDEX_EXPORT_BASE_ID } from '~/types/exports.types';
// import { getDistance as getDistanceMeters } from 'geolib';
import * as documentApi from '~/api/documentApi';
import {
    Estate,
    ESTATE_PROPERTY_COMMERCE_ARR,
    ESTATE_PROPERTY_COUNTRY_ARR,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX,
    ESTATE_PROPERTY_TYPE_HOUSE_PART,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_TYPE_RENT_ID
} from '~/types/estate.types';
import { Contact, CONTACT_TYPE_PRIVATE_PERSON_ID } from '~/types/contacts.types';
import { EXPIRED_CALL_STATUS_ERROR, matchExpiredCallStatus } from '~/api/cell/expiredCallsApi';
import authStore from '~/stores/authStore';
import deepCopy from '~/common/deepCopy';

export const ROOMS_SEPARATOR = '-';
export const ROOMS_SEPARATOR_REG = /([\d,.]+)/g;

const calcRoomsAreasSum = (allRoomsArea: string): number =>
    // @ts-ignore
    (allRoomsArea.match(ROOMS_SEPARATOR_REG) || []).reduce((sum: number, currentNumber: string) => {
        return sum + Number(currentNumber);
    }, 0);

export const FOREIGN_GEO_LAT_MAX = 42;

export const checkValidExportHelper = async (estate: Estate, export_base_id: number, contact: Contact | null): Promise<string[]> => {
    const {
        estate_id,
        type,
        photos,
        description,
        ceilingHeight,
        propertyType,
        newbuilding_house_id,
        houseNumber,
        livingArea,
        kitchenArea,
        totalArea,
        allRoomsArea,
        price,
        roomsForSaleCount,
        roomsCount,
        floorNumber,
        floorsCount,
        highway_id,
        distanceFromMKAD,
        isFake,
        parkingType,
        contact_id,
        landStatus,
        buildingIsNotReadyYet,
        lastOutgoingCallToOwnerTime,
        garageType,
        materialType,
        shareAmount,
        cadastralNumber,
        region,
        decoration,
        newbuilding,
        geo_lat,
        geo_lon,
        bedroomsCount,
        vatType,
        repairType
    } = estate;

    const errors: string[] = [];

    if (geo_lat < FOREIGN_GEO_LAT_MAX) {
        return errors;
    }

    // if (
    //     export_base_id === YANDEX_EXPORT_BASE_ID &&
    //     !authStore.currentUser.telegramUsername &&
    //     document.location.href.includes('ogrk')
    // ) {
    //     errors.push('Для экспорта в Яндекс.Недвижимость необходимо подключить Telegram к CRM');
    // }

    // if (newbuilding) {
    //     const meters = getDistanceMeters(
    //         { latitude: geo_lat, longitude: g    .eo_lon },
    //         { latitude: newbuilding.geo_lat, longitude: newbuilding.geo_lon }
    //     );
    //     if (meters > 4000) {
    //         errors.push('Расстояние между ЖК и Адресом более 4км');
    //     }
    // }

    if (export_base_id === YANDEX_EXPORT_BASE_ID && (!photos || photos.length < 4)) {
        errors.push('Для экспорта в Яндекс загрузите не менее 4 фотограии');
    }

    if (!description) {
        errors.push('У объекта пустое описание');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION && !contact) {
        errors.push('Контакт Дольщика (Продавца) не найден / не прикреплен / еще не загрузился');
    }

    if ([ESTATE_PROPERTY_TYPE_PART, ESTATE_PROPERTY_TYPE_HOUSE_PART].includes(propertyType) && !shareAmount) {
        errors.push('Укажите Долю в квартире/доме');
    }

    if (ESTATE_PROPERTY_HOUSES.includes(propertyType) && export_base_id === AVITO_EXPORT_BASE_ID && !bedroomsCount) {
        errors.push('Укажите количество спален в доме');
    }
    if (
        [...ESTATE_PROPERTY_HOUSES, ESTATE_PROPERTY_TYPE_FLAT].includes(propertyType) &&
        export_base_id === AVITO_EXPORT_BASE_ID &&
        repairType === null
    ) {
        errors.push('Укажите Ремонт в доме');
    }

    if ([ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType) && !newbuilding_house_id) {
        errors.push('Для новостройки необходимо указать корпус в ЖК');
    }

    if (![ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType) && buildingIsNotReadyYet) {
        errors.push('Вторичка не может быть в еще не сданном доме');
    }

    if ([ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType) && !decoration) {
        errors.push('Необходимо указать Отделку квартиры');
    }

    if (!newbuilding_house_id && !houseNumber && ESTATE_PROPERTY_FLATS_ARR.includes(propertyType)) {
        errors.push('Номер дома не указан');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_ROOM && !calcRoomsAreasSum(allRoomsArea || '')) {
        errors.push('Укажите площадь комнат(ы)');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_ROOM && calcRoomsAreasSum(allRoomsArea || '') > Number(totalArea) + Number(kitchenArea)) {
        errors.push('При экспорте комнат(ы) "Площадь комнат" в сумме должна быть не больше всей жилой (+кухни, если указано)');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_ROOM && Number(roomsCount) < Number(roomsForSaleCount)) {
        errors.push('Количество комнат на продажу не должно быть больше общего количества комнат в квартире');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_COMMERCE_LAND && !landStatus) {
        errors.push('Укажите категорию земли');
    }

    if (Number(totalArea) && calcRoomsAreasSum(allRoomsArea || '') >= Number(totalArea)) {
        errors.push('"Площадь комнат" в сумме должна быть не больше общей площади');
    }

    if (!ceilingHeight && ESTATE_PROPERTY_FLATS_ARR.includes(propertyType)) {
        errors.push('Высота потолков не указана');
    }

    if (!price) {
        errors.push('Стоимость объекта не указана');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_CAR_PLACEMENT && !parkingType) {
        errors.push('Тип парковки не указан');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_GARAGE && !garageType) {
        errors.push('Тип гаража не указан');
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_GARAGE_BOX && !materialType) {
        errors.push('Тип бокса не указан');
    }

    if (description.match(/(\+?7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/)) {
        errors.push('В описание запрещено указывать номер телефона и любую другую контактную информацию');
    }

    if (description.match(/\!{4,}/)) {
        errors.push('Нельзя в тексте больше трех восклицательных знаков подряд');
    }

    if (
        Number(totalArea) &&
        Number(kitchenArea) &&
        Number(livingArea) &&
        Number(totalArea) &&
        kitchenArea &&
        livingArea + kitchenArea >= totalArea - 4
    ) {
        errors.push('Жилая площадь + площадь кухни должна быть не менее чем на 4 метра меньше общей');
    }

    if (
        Number(kitchenArea) &&
        ((Number(totalArea) && Number(totalArea) > 25) || propertyType === ESTATE_PROPERTY_TYPE_ROOM) &&
        Number(kitchenArea) < 4
    ) {
        errors.push('Площадь кухни явно занижена');
    }

    if (Number(floorNumber) > Number(floorsCount)) {
        errors.push('Этаж больше количества этажей в доме');
    }

    if ((region || '').includes('Мос') && ESTATE_PROPERTY_COUNTRY_ARR.includes(propertyType)) {
        if (!highway_id) {
            errors.push('Не указано шоссе');
        }

        // if (!distanceFromMKAD) {
        //     errors.push('Не указано расстояние до МКАД');
        // }
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_SHOPPING) {
        if (!Number(floorNumber) || !Number(floorsCount)) {
            errors.push('Необходимо указать этаж и общее количество этажей в здание');
        }
    }

    if ([EXPIRED_CALL_STATUS_ERROR].includes(matchExpiredCallStatus(lastOutgoingCallToOwnerTime || 0, isFake))) {
        errors.push('Нет звонков Продавцу (Посреднику) более 14 дней');
    }

    if (cadastralNumber && !cadastralNumber.match(/\d+\:\d+\:\d+\:\d+/)) {
        errors.push('Неверный формат кадастрового номера. Пример корректного номера: 47:14:1203001:814');
    }

    if (description && description.length > 10 && (description.match(/[А-Я]/g) || []).length / description.length > 0.2) {
        errors.push('Слишком много заглавных букв в описание');
    }

    if (ESTATE_PROPERTY_COMMERCE_ARR.includes(propertyType) && !vatType) {
        errors.push('Укажите тип НДС или УСН');
    }

    if (
        export_base_id === AVITO_EXPORT_BASE_ID &&
        type === ESTATE_TYPE_RENT_ID &&
        [ESTATE_PROPERTY_TYPE_FLAT, ...ESTATE_PROPERTY_HOUSES].includes(propertyType)
    ) {
        if (!estate['otherUtilities'] && !estate['otherUtilitiesPayment']) {
            errors.push('Укажите месячную плату за другие ЖКУ или пометьте, что она включена в стоимость');
        }
    }

    if (authStore.currentUser.access.maxEnableDebt && -1 * authStore.currentUser.balance >= authStore.currentUser.access.maxEnableDebt) {
        errors.push(`У вас превышен лимит долга за экспорт. Ваш лимит: ${authStore.currentUser.access.maxEnableDebt} руб.`);
    }

    if (
        propertyType === ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION &&
        contact &&
        contact.contactType === CONTACT_TYPE_PRIVATE_PERSON_ID &&
        (!contact.firstName ||
            contact.firstName.length < 2 ||
            !contact.lastName ||
            contact.lastName.length < 2 ||
            !contact.middleName ||
            contact.middleName.length < 2)
    ) {
        errors.push(
            'Для экспорта переуступки необходимо заполнить реальное ФИО Дольщика (Продавца), т.к. он в дальнейшем будет проверяться по базе ЕГРН'
        );
    }

    if (propertyType === ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION && export_base_id === AVITO_EXPORT_BASE_ID) {
        try {
            if (!(await documentApi.checkDDUDocuments(estate_id))) {
                errors.push('Для экспорта переуступки необходимо прикрепить документы в папку ДДУ в карточке этого объекта');
            }
        } catch (err) {
            errors.push(err.toString());
        }
    }

    return errors;
};

export const calcExportPriceByEstateCost = (
    estate: Estate,
    pricesModeByEstateCost: boolean,
    price: ExportingTariffPrice
): number | null => {
    if (!pricesModeByEstateCost) {
        return price.dailyPrice;
    }

    const estateCosts = deepCopy(price.estateCosts).sort((c1, c2) =>
        (c1.maxEstateCost || Infinity) > (c2.maxEstateCost || Infinity) ? 1 : -1
    );
    const cost = estateCosts.find(cost => estate.price < (cost.maxEstateCost || Infinity));
    return cost?.price ?? price.dailyPrice ?? 0;
};
