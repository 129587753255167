import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import useConstructor from '~/common/useConstructor';

import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';
import { CallRateCategory } from '~/types/callRates.types';
import commonStore from '~/stores/commonStore';

import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';

import CallRateCategoryOptionsEditing from './CallRateCategoryOptionsEditing';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

type CallRateCategoryModalProps = HandlerEditingWrappedProps & { handleClose: () => void };

const CallRateCategoryModal = (props: CallRateCategoryModalProps) => {
    useConstructor(() => {
        const { item_id } = props;

        callRateCategoryStore.fetchItem(item_id);

        if (item_id === CREATING_ITEM_ID) {
            const item: Partial<CallRateCategory> = {
                category_id: item_id,
                title: '',
                enable: true,
                options: []
            };

            callRateCategoryStore.setEditingItem(item_id, item);
        }
    });

    const handleCreate = async (event: React.SyntheticEvent) => {
        const { item_id, handleClose } = props;

        if (item_id === CREATING_ITEM_ID) {
            await callRateCategoryStore.createItem();
        } else {
            await callRateCategoryStore.saveItem(item_id);
        }

        const { errors } = callRateCategoryStore.getItem(item_id);
        if (errors && errors.length) {
            return;
        }

        handleClose();
        callRateCategoryStore.fetchList();
    };

    const handleDelete = async () => {
        const { item_id, handleClose } = props;
        callRateCategoryStore.setEditingItem(item_id, {
            enable: false
        });
        await callRateCategoryStore.saveItem(item_id);

        handleClose();

        callRateCategoryStore.fetchList();
    };

    const { handleClose, item_id, handleChange, handleTextAreaChange } = props;
    const { editingItem, loadingItem, errors } = callRateCategoryStore.getItem(item_id);

    const { title } = editingItem || {};

    const modalActions: ModalBtnAction[] = [
        { onClick: handleClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
        {
            onClick: handleCreate,
            label: item_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            disabled: loadingItem,
            color: 'primary',
            variant: 'outlined',
            startIcon: <SaveIcon />
        }
    ];

    if (item_id > CREATING_ITEM_ID) {
        modalActions.splice(1, 0, {
            onClick: handleDelete,
            label: 'Удалить',
            disabled: loadingItem,
            variant: 'text',
            color: 'secondary',
            startIcon: <DeleteIcon />
        });
    }

    return (
        <Modal
            maxWidth="sm"
            onClose={handleClose}
            actions={modalActions}
            fullScreen={commonStore.isMobile}
            header={
                <>
                    <Icon name="street view" /> &nbsp; Критерий оценки звонков
                </>
            }
        >
            <ModalContent>
                <ListError errors={Array.from(errors || [])} />

                <Grid>
                    <Grid.Column>
                        <TextInput label="Название критерия" value={title} name="title" onChange={handleChange} variant="classic" />
                    </Grid.Column>
                    <Grid.Column>
                        <Paper elevation={2} style={{ padding: '1rem' }}>
                            <Typography variant="subtitle1">Опции для оценки</Typography>
                            {editingItem?.options && <CallRateCategoryOptionsEditing category_id={item_id} />}
                        </Paper>
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default HandlerEditingBlockWrapper(callRateCategoryStore, observer(CallRateCategoryModal));
