import React, { useState, useMemo, useCallback } from 'react';

import { TelegramMessage, fetchUserTelegramMessages, sendTestTelegramMessage } from '~/api/userApi';

import commonStore from '~/stores/commonStore';

import TelegramIcon from '@material-ui/icons/Telegram';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '~ui/Button';
import { SuccessMessage, WarningMessage } from '~ui/Message';
import Modal, { ModalContent } from '~ui/Modal';

import ChatMessage from '~/components/Chat/ChatMessage';
import ListError from '~/components/ListError';

import SendTelegramTestMessageBtn from './SendTelegramTestMessageBtn';

const UserTelegramMessagesModal = ({ user_id, onClose }: { user_id: number; onClose: () => void }) => {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState<TelegramMessage[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    useMemo(() => {
        fetchUserTelegramMessages(user_id)
            .then(setMessages)
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, []);

    return (
        <Modal
            maxWidth="md"
            onClose={onClose}
            loading={loading}
            header={
                <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
                    <TelegramIcon />
                    <Typography>Сообщения от бота @agentbase_bot</Typography>
                    <SendTelegramTestMessageBtn user_id={user_id} />
                </Box>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />

                {messages.length === 100 && <WarningMessage>Отображено только 100 последний сообщений</WarningMessage>}
                {!loading && messages.length === 0 && <WarningMessage>Сообщений не найдено</WarningMessage>}

                {messages.map(({ message_id, message, createTime, direction }) => (
                    <ChatMessage
                        key={message_id}
                        time={createTime}
                        message={message}
                        avatar={'/static/tg_bot.jpg'}
                        name={'@agentbase_bot'}
                        deliveryTime={null}
                        readTime={null}
                        urlifyDisable
                        reverse={direction === 'in'}
                    />
                ))}
            </ModalContent>
        </Modal>
    );
};

export default UserTelegramMessagesModal;
