import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';
import callRateStore from '~/stores/cell/callRateStore';
import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import useStoreEditingField from '~/stores/hooks/useStoreEditingField';
import { CellCallMetadata } from '~/stores/helpers/cellCalls.helpers';

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Modal, ModalActions, ModalContent } from '~ui/Modal';
import Button from '~ui/Button';
import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';
import Divider from '~ui/Divider';

import ListError from '~/components/ListError';
import ButtonGroup from '~/components/Base/ButtonGroup';

import ListenOrDownloadBox from './ListenOrDownloadBox';
import RateCallCategory from './RateCallCategory';

const RateCellCallModal = ({ callMetadata, onClose }: { callMetadata: CellCallMetadata; onClose: () => void }) => {
    useMemo(() => {
        callRateStore.fetchItem(CREATING_ITEM_ID);
        callRateStore.setEditingItem(CREATING_ITEM_ID, {
            call_id: callMetadata.call_id,
            category_id: null,
            categoryOptionsUsed: []
        });
    }, []);

    const { loadingItem, errors } = callRateStore.getItem(CREATING_ITEM_ID);

    const [category_id, handleCategoryId] = useStoreEditingField(callRateStore, CREATING_ITEM_ID, 'category_id', null);
    const [rate, _, handleRate] = useStoreEditingField(callRateStore, CREATING_ITEM_ID, 'rate', null);
    const [positiveFeedback, handlePositiveFeedback] = useStoreEditingField(callRateStore, CREATING_ITEM_ID, 'positiveFeedback', null);
    const [improvementSuggestions, handleImprovementSuggestions] = useStoreEditingField(
        callRateStore,
        CREATING_ITEM_ID,
        'improvementSuggestions',
        null
    );
    const [comment, handleComment] = useStoreEditingField(callRateStore, CREATING_ITEM_ID, 'comment', null);

    const handleCreate = async () => {
        if (await callRateStore.createItem()) {
            onClose();
        }
    };

    return (
        <Modal onClose={!loadingItem ? onClose : null} fullScreen={commonStore.isMobile} fullWidth maxWidth="sm" header="Оценка звонка">
            <ModalContent dividers>
                <ListenOrDownloadBox callMetadata={callMetadata} showDownloadLink={false} />
                <Divider />
                <ListError errors={errors} />
                <Grid>
                    {callRateCategoryStore.allCategoriesDropdown.length > 0 && <RateCallCategory call_rate_id={CREATING_ITEM_ID} />}
                    <Grid.Column>
                        <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: '2rem' }}>
                            <Typography variant="subtitle1" color="textPrimary">
                                Ваша оценка
                            </Typography>
                            <Rating size="large" max={5} precision={1} value={rate} onChange={(_, value) => handleRate(value)} />
                        </Box>
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            label="Что понравилось?"
                            value={positiveFeedback}
                            name="positiveFeedback"
                            onChange={handlePositiveFeedback}
                            multiline
                            rowsMax={4}
                            rows={1}
                            variant="classic"
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            label="Что можно улучшить?"
                            value={improvementSuggestions}
                            name="improvementSuggestions"
                            onChange={handleImprovementSuggestions}
                            multiline
                            rowsMax={4}
                            rows={1}
                            variant="classic"
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            label="Комментарий"
                            value={comment}
                            name="comment"
                            onChange={handleComment}
                            multiline
                            rowsMax={4}
                            rows={1}
                            variant="classic"
                            fullWidth
                        />
                    </Grid.Column>
                </Grid>
            </ModalContent>

            <ModalActions>
                <Button disabled={loadingItem} onClick={onClose} color="secondary">
                    Отменить
                </Button>
                <Button disabled={loadingItem} variant="outlined" onClick={handleCreate} color="primary" loading={loadingItem}>
                    Оценить звонок
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default observer(RateCellCallModal);
