import React from 'react';
import { observer } from 'mobx-react';

import exportingBaseStore from '~/stores/export/exportingBaseStore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import estateL10n from '~/L10n/estate.L10n';

type ExportTariffPriceModalHeaderProps = {
    tariff_id: number;
    export_base_id: number;
};

const ExportTariffPriceModalHeader = ({ tariff_id, export_base_id }: ExportTariffPriceModalHeaderProps) => {
    const { region_id, propertyType } = exportingBaseStore
        .getItem(export_base_id)
        .editingItem.tariffs.find(tariff => tariff.tariff_id === tariff_id);

    return (
        <Box padding={2} display="flex" alignItems="center" style={{ gap: '2rem' }}>
            <Typography variant="caption" color="textSecondary">
                регион
            </Typography>
            <Typography variant="subtitle1" color="primary">
                {exportingBaseStore.regionsById.get(region_id)?.title}
            </Typography>
            <Typography variant="caption" color="textSecondary">
                тип объекта
            </Typography>
            <Typography variant="subtitle1" color="primary">
                {estateL10n.PROPERTY_TYPE_FIND(propertyType)}
            </Typography>
        </Box>
    );
};

export default observer(ExportTariffPriceModalHeader);
