import React from 'react';
import { observer } from 'mobx-react';

import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { HandleChangeType } from '~/components/Base/EditField';

import { TableCell } from '~ui/Table';

import { NumberInput } from '~ui/TextInput';

type ExportPriceCostTableCellProps = {
    export_base_id: number;
    tariff_id: number;
    name: string;
    minEstateCost: number;
    maxEstateCost: number;
};

const ExportPriceCostTableCell = ({ export_base_id, tariff_id, minEstateCost, maxEstateCost, name }: ExportPriceCostTableCellProps) => {
    const { prices } = exportingBaseStore.getItem(export_base_id).editingItem.tariffs.find(tariff => tariff.tariff_id === tariff_id);

    const price = prices.find(price => price.name === name);

    const priceEstateCost = price.estateCosts.find(
        estateCost => estateCost.minEstateCost === minEstateCost && estateCost.maxEstateCost === maxEstateCost
    );

    const handleChange: HandleChangeType = (_, { value }) => {
        priceEstateCost.price = Number(value);
    };

    return (
        <TableCell key={name} align="right">
            <NumberInput
                value={priceEstateCost.price}
                onChange={handleChange}
                extraLabel={<>₽</>}
                style={{ minWidth: '11em' }}
                step={0.25}
                min={0}
                size="small"
                fullWidth
            />
        </TableCell>
    );
};

export default observer(ExportPriceCostTableCell);
