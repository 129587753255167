import React from 'react';
import { observer } from 'mobx-react';

import callRateStore from '~/stores/cell/callRateStore';
import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';
import useStoreEditingField from '~/stores/hooks/useStoreEditingField';

import Typography from '@material-ui/core/Typography';

import Grid from '~ui/Grid';

import ButtonGroup from '~/components/Base/ButtonGroup';

import RateCallCategoryOptions from './RateCallCategoryOptions';

const RateCallCategory = ({ call_rate_id }: { call_rate_id: number }) => {
    const [category_id, handleCategoryId] = useStoreEditingField(callRateStore, call_rate_id, 'category_id', null);

    return (
        <Grid.Column>
            <Typography variant="subtitle1" color="textPrimary">
                Критерий оценки
            </Typography>
            <ButtonGroup
                options={callRateCategoryStore.allCategoriesDropdown}
                value={category_id}
                name="category_id"
                handleChange={handleCategoryId}
            />
            {category_id && <RateCallCategoryOptions call_rate_id={call_rate_id} category_id={category_id} />}
        </Grid.Column>
    );
};

export default observer(RateCallCategory);
