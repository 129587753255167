import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';

import { Modal, ModalContent } from '~ui/Modal';
import { Table, TableRow, TableCell, TableBody, TableHead } from '~ui/Table';

import LoaderAwait from '~/components/Base/LoaderAwait';
import Time from '~/components/Base/Time';

type CRMChangesModalProps = {
    onClose: () => void;
};

const CRMChangesModal = ({ onClose }: CRMChangesModalProps) => {
    useEffect(() => {
        commonStore.fetchCRMChanges();
    }, []);

    const { loadingCRMChanges, crmChangesList } = commonStore;

    return (
        <Modal onClose={onClose} header="История нововведений в CRM">
            <ModalContent>
                <Table size="small" compact>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '130px' }}>Дата</TableCell>
                            <TableCell>Изменение</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {crmChangesList.map(({ releaseTime, title }) => (
                            <TableRow key={releaseTime}>
                                <TableCell style={{ width: '130px' }}>
                                    <Time time={releaseTime} />
                                </TableCell>
                                <TableCell>{title}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <LoaderAwait active={loadingCRMChanges} dimmer />
            </ModalContent>
        </Modal>
    );
};

export default observer(CRMChangesModal);
