import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TabsMenu, { TabPanel, TabType } from '~/components/Base/TabsMenu';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';

import Tags from './Tags';
import Triggers from './Triggers';
import Access from './Access';
import CellOperatorsSettings from './CellOperatorsSettings';
import SourcePhonesSettings from './SourcePhonesSettings';
import CallSettings from './CallSettings';
import WhatsappSettings from './WhatsappSettings';
import CrmSettings from './CrmSettings';
import SppSettings from './SppSettings';
import LifeComplexFeeds from './LifeComplexFeeds';

import authStore from '~/stores/authStore';
import settingStore from '~/stores/settingStore';
import { ACCESS_GROUP, ACCESS_READ } from '~/types/access.types';
import Divider from '~ui/Divider';

const settingsListStyles = makeStyles(({ spacing }) => ({
    root: {
        width: '400px',
        maxWidth: 'calc(100vw - 3rem)',
        maxHeight: 'calc(100vh - 150px)',
        overflowY: 'scroll',
        padding: 0,
        margin: spacing(2)
    },
    outerBox: {
        width: '400px',
        maxWidth: 'calc(100vw - 3rem)',
        position: 'relative',
        minHeight: '100px'
    }
}));

export const SettingsListPaper = ({ children }: { children: ReactNode }) => {
    const classes = settingsListStyles();
    return <Paper className={classes.root}>{children}</Paper>;
};

const TAB_CRM = 'crm';
const TAB_TAGS = 'tags';
const TAB_TRIGGERS = 'triggers';
const TAB_ACCESS = 'access';
const TAB_CELL_OPERATORS = 'cellOperators';
const TAB_CALL_SETTINGS = 'callSettings';
const TAB_WHATSAPP = 'whatsapp';
const TAB_SPP = 'spp';
const TAB_FEEDS = 'feeds';

const Settings = () => {
    if (!authStore.matchAccess(settingStore.moduleName, ACCESS_READ, ACCESS_GROUP)) {
        return null;
    }

    const { tabName }: { tabName: string } = useParams();

    const settingsTabs: TabType<string>[] = [
        { label: 'CRM', icon: <SettingsIcon />, value: TAB_CRM, path: `/settings/${TAB_CRM}` },
        { label: 'Доступы', icon: <VpnKeyIcon />, value: TAB_ACCESS, path: `/settings/${TAB_ACCESS}` },
        { label: 'Тэги', icon: <LocalOfferIcon />, value: TAB_TAGS, path: `/settings/${TAB_TAGS}` },
        { label: 'Триггеры', icon: <FlashOnIcon />, value: TAB_TRIGGERS, path: `/settings/${TAB_TRIGGERS}` },
        { label: 'Телефония', icon: <SettingsPhoneIcon />, value: TAB_CELL_OPERATORS, path: `/settings/${TAB_CELL_OPERATORS}` },
        { label: 'Звонки', icon: <PhonelinkSetupIcon />, value: TAB_CALL_SETTINGS, path: `/settings/${TAB_CALL_SETTINGS}` },
        { label: 'WhatsApp', icon: <WhatsAppIcon />, value: TAB_WHATSAPP, path: `/settings/${TAB_WHATSAPP}` }
    ];

    if (['crm.agentbase.ru', 'localhost:3000'].includes(document.location.host)) {
        settingsTabs.push({ label: 'СПП', icon: <StorageIcon />, value: TAB_SPP, path: `/settings/${TAB_SPP}` });
        settingsTabs.push({ label: 'Фиды застройщиков', icon: <StorageIcon />, value: TAB_FEEDS, path: `/settings/${TAB_FEEDS}` });
    }

    return (
        <TabsMenu value={tabName} tabs={settingsTabs}>
            <TabPanel value={tabName} index={TAB_CRM}>
                <CrmSettings />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_TAGS}>
                <Tags />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_TRIGGERS}>
                <Triggers />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_ACCESS}>
                <Access />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_CELL_OPERATORS}>
                <CellOperatorsSettings />
                <Divider />
                <SourcePhonesSettings />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_CALL_SETTINGS}>
                <CallSettings />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_WHATSAPP}>
                <WhatsappSettings />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_SPP}>
                <SppSettings />
            </TabPanel>
            <TabPanel value={tabName} index={TAB_FEEDS}>
                <LifeComplexFeeds />
            </TabPanel>
        </TabsMenu>
    );
};

export default Settings;
