import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { EstateVideo } from '~/types/estate.types';

import estateStore from '~/stores/estateStore';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import Grid from '@material-ui/core/Grid';

import Button from '~ui/Button';
import TextInput from '~ui/TextInput';

import ListError from '~/components/ListError';

const clearYoutubeId = link => {
    let ID = '';
    const url = link.replace(/(>\|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
    }
    return ID;
};

const matchSourceAndId = (url: string): { videoId: string; serviceId: number } => {
    if (url.includes('youtube.com')) {
        const videoId = clearYoutubeId(url);
        return {
            serviceId: 1,
            videoId
        };
    }
    if (url.includes('rutube.ru')) {
        const videoId = url.match(/video\/(.+)\/?/)[1].replace('/', '');
        return {
            serviceId: 2,
            videoId
        };
    }
    if (url.includes('vk.com') || url.includes('vkvideo.ru')) {
        let videoId: string;
        try {
            videoId = url.match(/video-?((\d+)_(\d+))/)[1];
        } catch (e) {
            const parser = new URL(url);
            if (!parser.searchParams.get('oid')) {
                throw new Error();
            }
            videoId = parser.searchParams.get('oid') + '_' + parser.searchParams.get('id');
        }
        return {
            serviceId: 3,
            videoId
        };
    }
    throw new Error();
};

const matchVideoUrl = (serviceId: number, videoId: string): string => {
    switch (serviceId) {
        case 1:
            return `https://www.youtube.com/watch?v=${videoId}`;
        case 2:
            return `https://rutube.ru/video/${videoId}/`;
        case 3:
            return `https://vkvideo.ru/video${videoId}`;
    }
    throw new Error();
};

type VideoItemEditingProps = {
    estate_id: number;
    serviceId: number;
    videoId: string;
    onDelete: VoidFunction;
    onEdit: (data: { serviceId: number; videoId: string }) => void;
};

const VideoItemEditing = observer(({ videoId, serviceId, onDelete, onEdit }: VideoItemEditingProps) => {
    const [error, setError] = useState('');
    const [url, setUrl] = useState(videoId && serviceId ? matchVideoUrl(serviceId, videoId) : '');

    const handleYoutubeChange = (event: React.SyntheticEvent, { value }: { value: string }) => {
        setError('');
        setUrl(value);
        try {
            const { videoId, serviceId } = matchSourceAndId(value);
            onEdit({ videoId, serviceId });
            setUrl(matchVideoUrl(serviceId, videoId));
        } catch (error) {
            setError('Ошибка в ссылке на видео-ролик, не распознан сервис');
        }
    };

    return (
        <>
            <Grid item xs={3}>
                {!serviceId && <label className="crm-Estate__field_label">Видео</label>}
                {serviceId === 1 && (
                    <label className="crm-Estate__field_label" style={{ color: 'red' }}>
                        Youtube
                    </label>
                )}
                {serviceId === 2 && (
                    <label className="crm-Estate__field_label" style={{ color: 'black' }}>
                        RuTube
                    </label>
                )}
                {serviceId === 3 && (
                    <label className="crm-Estate__field_label" style={{ color: 'blue' }}>
                        VK Video
                    </label>
                )}
            </Grid>
            <Grid item xs={8}>
                <TextInput
                    error={Boolean(error)}
                    type="text"
                    label={error || 'Ссылка на ролик в YouTube/Rutube/Vk video'}
                    size="small"
                    value={url}
                    name="buildYear"
                    onChange={handleYoutubeChange}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={1}>
                <Button icon={<ClearIcon />} color="inherit" onClick={onDelete} tooltip="Удалить ролик" />
            </Grid>
        </>
    );
});

const VideoBlockEditing = (props: { estate_id: number; videos?: EstateVideo[] }) => {
    const [error, setError] = useState('');
    const { estate_id } = props;

    useMemo(() => {
        const estate = {
            videos: props.videos && props.videos.length ? props.videos : [{ videoId: '', serviceId: 0 }]
        };
        estateStore.setEditingItem(estate_id, estate);
    }, [estate_id]);

    const { videos } = estateStore.getItem(estate_id).editingItem;

    const addVideo = () => {
        const { videos } = estateStore.getItem(estate_id).editingItem;
        estateStore.setEditingItem(estate_id, { videos: [...videos, { videoId: '', serviceId: 0 }] });
    };

    const deleteVideo = (index: number) => {
        let { videos } = estateStore.getItem(estate_id).editingItem;
        videos = [...videos];
        videos.splice(index, 1);
        estateStore.setEditingItem(estate_id, { videos: [...videos] });
    };

    const onEdit = (index: number) => ({ serviceId, videoId }: { serviceId: number; videoId: string }) => {
        const { videos } = estateStore.getItem(estate_id).editingItem;
        setError('');
        if (videos.find(video => video.serviceId === serviceId)) {
            setError('Уже есть видео с данного видео-хостинга');
        } else {
            estateStore.changeArrayValue(estate_id, 'videos', index, 'serviceId', serviceId);
            estateStore.changeArrayValue(estate_id, 'videos', index, 'videoId', videoId);
        }
    };

    return (
        <div className="crm-Item__editingMode">
            {error && <ListError errors={[error]} />}
            <Grid container alignItems="center">
                {videos.map(({ videoId, serviceId }, index) => (
                    <VideoItemEditing
                        key={`video-${index}`}
                        estate_id={estate_id}
                        videoId={videoId}
                        serviceId={serviceId}
                        onDelete={() => deleteVideo(index)}
                        onEdit={onEdit(index)}
                    />
                ))}
                {videos.length < 3 && (
                    <Grid item xs={12}>
                        <Button size="small" startIcon={<AddIcon />} variant="outlined" color="primary" onClick={addVideo}>
                            Добавить ролик
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default observer(VideoBlockEditing);
