import React, { Component } from 'react';

import { observer } from 'mobx-react';

import expiredCallStore from '~/stores/cell/expiredCallStore';
import commonStore from '~/stores/commonStore';
import estateStore from '~/stores/estateStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';

import EditField from '~/components/Base/EditField';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ListError from '~/components/ListError';

type ExpiredCallModalProps = HandlerEditingWrappedProps & {
    estate_id: number;
    handleClose: () => void;
};

@observer
class ExpiredCallModal extends Component<ExpiredCallModalProps> {
    constructor(props: ExpiredCallModalProps) {
        super(props);

        const { contact_id } = estateStore.getItem(props.estate_id).item || {};

        if (contact_id) {
            expiredCallStore.fetchItem(CREATING_ITEM_ID);
            expiredCallStore.setEditingItem(CREATING_ITEM_ID, { contact_id, reason: '' });
        }
    }

    handleSave = async () => {
        if (await expiredCallStore.createExpiredCall(this.props.estate_id)) {
            this.props.handleClose();
        }
    };

    render() {
        const { handleClose, handleTextAreaChange } = this.props;
        const {
            editingItem: { reason },
            loadingItem,
            errors
        } = expiredCallStore.getItem(CREATING_ITEM_ID);

        const actions: ModalBtnAction[] = [
            { onClick: handleClose, label: 'Отмена', variant: 'text', disabled: loadingItem },
            {
                onClick: this.handleSave,
                label: 'Создать звонок',
                loading: loadingItem,
                disabled: loadingItem || reason.length < 10,
                variant: 'outlined',
                color: 'primary'
            }
        ];

        return (
            <Modal
                disableBackdropClick={loadingItem}
                maxWidth="sm"
                fullScreen={commonStore.isMobile}
                onClose={handleClose}
                actions={actions}
                header="Просроченный звонок Продавцу (Посреднику)"
            >
                <ModalContent>
                    <ListError errors={Array.from(errors)} />

                    <div className="crm-Item__editingMode">
                        <EditField.Area label="Причина" value={reason} name="reason" onChange={handleTextAreaChange} />
                    </div>
                </ModalContent>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(expiredCallStore, ExpiredCallModal);
