import React, { Component, ChangeEvent } from 'react';

import { observer } from 'mobx-react';

import { UserFilter } from '~/types/users.types';

import accessStore from '~/stores/accessStore';
import userStore from '~/stores/userStore';

import SearchIcon from '@material-ui/icons/Search';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import DatePicker from '~ui/DatePicker';
import Divider from '~ui/Divider';
import Select from '~ui/Select';
import TextInput from '~ui/TextInput';

import FilterCell from '~/components/Lists/Common/FilterCell';

import { FilterMoreDetailsProps } from '../../ListFilterWrapper';

@observer
class FilterUsersMoreDetails extends Component<FilterMoreDetailsProps, {}> {
    changeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        userStore.changeFilter('search', event.target.value);
    };

    handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        userStore.changeFilter(event.target.name as keyof UserFilter, event.target.checked);
    };

    handleChange = (event, { type, name, value }: { type: string; name: keyof UserFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        userStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    setUnixTime = (name: keyof UserFilter, time: number) => {
        userStore.changeFilter(name, time);
    };

    render() {
        const { loadingList, accessModesDropdown } = accessStore;
        const {
            search,
            fromDateUserDismissal,
            toDateUserDismissal,
            fromDateUserInState,
            toDateUserInState,
            enable,
            onlineOnly,
            access_id
        } = userStore.listFilter;

        return (
            <div>
                <TextInput onChange={this.changeSearch} label="Поиск" value={search} fullWidth startLabel={<SearchIcon />} />

                <Divider />

                <Select
                    name="access_id"
                    value={access_id}
                    onChange={this.handleChange}
                    label="Доступ"
                    options={accessModesDropdown || []}
                    loading={loadingList}
                    clearable
                    variant="classic"
                    size="small"
                    style={{ minWidth: '180px' }}
                />

                <FormControlLabel
                    control={<Checkbox checked={onlineOnly} onChange={this.handleCheckbox} name="onlineOnly" />}
                    label="онлайн"
                    style={{ marginLeft: '1em' }}
                />

                <Divider />

                <FilterCell title={enable ? 'Пришел с' : 'Уволился от'}>
                    <Grid item sm={6} xs={12}>
                        <DatePicker
                            label={enable ? 'Пришел с' : 'Уволился от'}
                            variant="standard"
                            value={enable ? fromDateUserInState : fromDateUserDismissal}
                            onChange={time => this.setUnixTime(enable ? 'fromDateUserInState' : 'fromDateUserDismissal', time)}
                        />
                    </Grid>
                </FilterCell>

                <FilterCell title={enable ? 'Пришел до' : 'Уволился до'}>
                    <Grid item sm={6} xs={12}>
                        <DatePicker
                            label={enable ? 'Пришел до' : 'Уволился до'}
                            variant="standard"
                            value={enable ? toDateUserInState : toDateUserDismissal}
                            onChange={time => this.setUnixTime(enable ? 'toDateUserInState' : 'toDateUserDismissal', time)}
                        />
                    </Grid>
                </FilterCell>
            </div>
        );
    }
}

export default FilterUsersMoreDetails;
