import React, { Fragment } from 'react';
import { TableCell } from '~ui/Table';

import { observer } from 'mobx-react';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import { useToggle } from '~ui/Modal';
import Button from '~ui/Button';

import ExportTariffPriceModal from './ExportTariffPriceModal';
import PriceMinMax from '~/components/Items/Wish/SubBlocks/PriceMinMax';

const ExportFeedTariffsPricesExtendedMode = ({ export_base_id, tariff_id }: { tariff_id: number; export_base_id: number }) => {
    const [showEditingModal, toggleShow] = useToggle();

    const { prices } = exportingBaseStore.getItem(export_base_id).editingItem.tariffs.find(tariff => tariff.tariff_id === tariff_id);
    const isExtraExist = Boolean(prices[0].extraName);

    return (
        <Fragment>
            {prices.map(({ name, dailyPrice, price_id, minDays, isPremium, estateCosts }) => {
                const priceMin = Math.min(...estateCosts.map(({ price }) => price));
                const priceMax = Math.max(...estateCosts.map(({ price }) => price));

                return (
                    <TableCell align="center" key={name} negative={isPremium}>
                        <Button fullWidth onClick={toggleShow}>
                            <PriceMinMax priceMin={priceMin} priceMax={priceMax} />{' '}
                            <span className="crm-List__monoField">&nbsp;{minDays === 1 ? 'в день' : `за ${minDays} дней`}</span>
                        </Button>
                    </TableCell>
                );
            })}
            {isExtraExist && (
                <TableCell align="right" key="isExtraExist">
                    {prices[0].extraPrice}
                </TableCell>
            )}
            {showEditingModal && <ExportTariffPriceModal onClose={toggleShow} export_base_id={export_base_id} tariff_id={tariff_id} />}
        </Fragment>
    );
};

export default observer(ExportFeedTariffsPricesExtendedMode);
