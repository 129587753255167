import React from 'react';
import { observer } from 'mobx-react';

import exportingBaseStore from '~/stores/export/exportingBaseStore';

import Modal, { ModalContent } from '~ui/Modal';
import Table, { TableHead, TableCell } from '~ui/Table';
import Select from '~ui/Select';

import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ExportTariffPriceModalHeader from './Blocks/ExportTariffPriceModalHeader';
import ExportTariffPriceByCostTableBody from './Blocks/ExportTariffPriceByCostTableBody';

import { MIN_DAYS_OPTIONS } from '~/components/Ads/Export/ExportFeedTariffsPrices';
import { TariffPriceHeaderOptions } from '~/types/exports.types';

type ExportTariffPriceModalProps = {
    onClose: () => void;
    tariff_id: number;
    export_base_id: number;
};

const ExportTariffPriceModal = ({ onClose, tariff_id, export_base_id }: ExportTariffPriceModalProps) => {
    const { prices } = exportingBaseStore.getItem(export_base_id).editingItem.tariffs.find(tariff => tariff.tariff_id === tariff_id);

    let pricesHeader: TariffPriceHeaderOptions[] = prices.map(({ name, minDays, isPremium }) => ({ name, minDays, isPremium }));

    const changeDaysHandle = (name: string, minDays: number) => {
        prices
            .filter(price => price.name == name)
            .forEach(price => {
                price.minDays = minDays;
            });
    };

    return (
        <Modal maxWidth="xl" onClose={onClose} header="Настройка тарифа в зависимости от цены объекта">
            <ModalContent style={{ paddingBottom: '2rem' }}>
                <Paper style={{ marginBottom: '1rem' }}>
                    <ExportTariffPriceModalHeader tariff_id={tariff_id} export_base_id={export_base_id} />
                </Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Цена объекта</TableCell>
                            {pricesHeader.map(header => (
                                <TableCell key={header.name}>{header.name}</TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                        <TableRow>
                            <TableCell>за период</TableCell>
                            {pricesHeader.map(header => (
                                <TableCell key={header.name}>
                                    <Select
                                        withoutBorder
                                        value={prices.find(price => price.name === header.name).minDays}
                                        options={MIN_DAYS_OPTIONS}
                                        onChange={(_, { value }) => changeDaysHandle(header.name, value)}
                                    />
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <ExportTariffPriceByCostTableBody tariff_id={tariff_id} export_base_id={export_base_id} />
                </Table>
            </ModalContent>
        </Modal>
    );
};

export default observer(ExportTariffPriceModal);
