import { execDaily } from '~/common/time';

import React, { Fragment } from 'react';

interface StatusIndicatorProps {
    color: string;
    title?: string;
    size?: number;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ color, title, size = 8 }) => {
    return (
        <span
            title={title}
            style={{
                display: 'inline-block',
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '50%',
                backgroundColor: color,
                marginRight: '5px',
                verticalAlign: 'middle'
            }}
        />
    );
};

export const DELTA_ONLINE_TIME = 5 * 60000;

const Time = ({ time, status = false, maxDaysToTime = 2000 }: { time?: string | number; status?: boolean; maxDaysToTime?: number }) => {
    if (typeof time !== 'number' && !status) {
        return null;
    }

    const realMicroTime = typeof time === 'number' ? (time < 1000000000000 ? time * 1000 : time) : new Date(String(time)).getTime();
    const { printTime, deltaTime } = execDaily(realMicroTime, maxDaysToTime);
    const printDate = new Date(realMicroTime).toLocaleString('ru');

    if (status && deltaTime < DELTA_ONLINE_TIME) {
        return (
            <Fragment>
                <StatusIndicator color="green" />
                &nbsp;онлайн
            </Fragment>
        );
    } else if (status) {
        return (
            <Fragment>
                <StatusIndicator color="red" />
                &nbsp;
                {printTime}
            </Fragment>
        );
    }
    return <span title={printDate}>{printTime}</span>;
};

export default Time;
