import React from 'react';

import exportStore from '~/stores/export/exportStore';
import commonStore from '~/stores/commonStore';
import { ExportingBaseFiltered } from '~/types/exports.types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PrintExportExternalStatus from '../SubBlocks/PrintExportExternalStatus';
import InfoPopup from '~/components/Base/InfoPopup';

const ExportListItemTitle = ({
    estate_id,
    export_base_id,
    cost,
    minDays,
    exportingBase,
    pricesModeByEstateCost
}: {
    estate_id: number;
    export_base_id: number;
    cost: number;
    minDays: number;
    exportingBase: ExportingBaseFiltered;
    pricesModeByEstateCost: boolean;
}) => {
    const { externalStatuses } = exportStore.getItem(estate_id).editingItem;

    const foundStatus = externalStatuses.find(externalStatus => externalStatus.export_base_id === export_base_id);
    const externalStatus = foundStatus ? foundStatus.externalStatus : null;
    const { name, regionName } = exportingBase;

    return (
        <div style={{ position: 'relative', zIndex: 1 }}>
            <Typography
                variant="subtitle1"
                style={{
                    display: 'flex',
                    gap: commonStore.isMobile ? 0 : '8px',
                    alignItems: commonStore.isMobile ? 'flex-start' : 'center',
                    flexDirection: commonStore.isMobile ? 'column' : 'row'
                }}
            >
                <div>
                    {name}
                    {regionName && (
                        <span style={{ fontWeight: 200, marginLeft: '8px' }}>
                            ({regionName} <InfoPopup content="Тариф согласно указанному региону" size="tiny" />)
                        </span>
                    )}
                </div>
                <Box mr={2}>
                    {cost ? `${cost} ₽/${minDays === 1 ? `день` : ` ${minDays} дней`}` : ''}
                    {pricesModeByEstateCost && <InfoPopup color="secondary" content="Цена зависит от цены объекта" size="tiny" />}
                </Box>
                {externalStatus && <PrintExportExternalStatus export_base_id={export_base_id} externalStatus={externalStatus} />}
            </Typography>
        </div>
    );
};

export default ExportListItemTitle;
