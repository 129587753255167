import { useRef } from 'react';

function useConstructor(callback: VoidFunction) {
    const hasBeenCalled = useRef(false);

    if (!hasBeenCalled.current) {
        callback();
        hasBeenCalled.current = true;
    }
}

export default useConstructor;
