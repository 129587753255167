import React, { useState } from 'react';
import { observer } from 'mobx-react';

import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { CIAN_EXPORT_BASE_ID, TariffPriceHeaderOptions } from '~/types/exports.types';
import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';

import estateL10n from '~/L10n/estate.L10n';
import ButtonGroup from '~/components/Base/ButtonGroup';

import LoaderAwait from '~/components/Base/LoaderAwait';
import Table, { TableCell, TableBody, TableRow, TableHead } from '~ui/Table';
import Checkbox, { Switch } from '~ui/Checkbox';

import ExportBaseRegions from '~/components/Ads/Export/ExportBaseRegions';
import ExportFeedTariffsPrices from '~/components/Ads/Export/ExportFeedTariffsPrices';
import ExportFeedTariffsPricesExtendedMode from './ExportFeedTariffsPricesExtendedMode';
import TariffPriceHeader from '~/components/Items/ExportFeed/Blocks/TariffPriceHeader';

import Box from '@material-ui/core/Box';
import InfoPopup from '~/components/Base/InfoPopup';

type ExportTableTariffsProps = {
    export_base_id: number;
};

const ExportTableTariffs = ({ export_base_id }: ExportTableTariffsProps) => {
    const [currentRegionId, changeCurrentRegionId] = useState(0);

    const handleChangeEnableTariff = (tariff_id: number, currentState: boolean) => {
        const index = exportingBaseStore.getIndexTariff(export_base_id, tariff_id);
        exportingBaseStore.changeArrayValue(export_base_id, 'tariffs', index, 'enable', !currentState);
    };

    const [type, setType] = useState(ESTATE_TYPE_SELL_ID);
    const handleChangeType = () => {
        setType(type === ESTATE_TYPE_SELL_ID ? ESTATE_TYPE_RENT_ID : ESTATE_TYPE_SELL_ID);
    };

    const { editingItem } = exportingBaseStore.getItem(export_base_id);
    if (!editingItem?.tariffs) {
        return <LoaderAwait active />;
    }

    const { tariffs } = editingItem;

    const { prices } = tariffs[0];

    const handleChangeModeByCost = (tariff_id: number, currentState: boolean) => {
        const index = exportingBaseStore.getIndexTariff(export_base_id, tariff_id);
        exportingBaseStore.changeArrayValue(export_base_id, 'tariffs', index, 'pricesModeByEstateCost', !currentState);
        tariffs
            .find(tariff => tariff.tariff_id === tariff_id)
            .prices.forEach(price => {
                price.estateCosts = price.estateCosts?.length
                    ? price.estateCosts
                    : [
                          {
                              price_cost_id: 0,
                              price_id: price.price_id,
                              minEstateCost: null,
                              maxEstateCost: null,
                              price: price.dailyPrice
                          }
                      ];
            });
    };

    let pricesHeader: TariffPriceHeaderOptions[] = prices.map(({ name, minDays, isPremium }) => ({ name, minDays, isPremium }));

    let isExtraExist = false;
    if (prices[0].extraName) {
        pricesHeader.push({ name: prices[0].extraName, isPremium: true, minDays: null });
        isExtraExist = true;
    }

    return (
        <Table maxHeight="calc(100vh - 200px)" size="small">
            <TableHead>
                <TableRow>
                    <ExportBaseRegions
                        colSpan={pricesHeader.length + 2}
                        export_base_id={export_base_id}
                        region_id={currentRegionId}
                        onRegionChange={changeCurrentRegionId}
                    />
                </TableRow>
                <TableRow>
                    <TableCell align="left">
                        <ButtonGroup buttonSet={estateL10n.ESTATE_DEAL_TYPE} name="type" value={type} handleChange={handleChangeType} />
                    </TableCell>
                    {pricesHeader.map(({ name, isPremium, minDays }, index) => (
                        <TableCell key={name} align="center" className="crm-Estate__fieldNowrap" negative={isPremium}>
                            {name}
                            {(!isExtraExist || index < pricesHeader.length - 1) && (
                                <TariffPriceHeader options={{ name, minDays, isPremium }} index={index} export_base_id={export_base_id} />
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {estateL10n.PROPERTY_TYPE.map(([propertyType, title]: [number, string]) => {
                    const foundTariff = tariffs.find(
                        tariff => tariff.region_id === currentRegionId && tariff.type === type && tariff.propertyType === propertyType
                    );
                    if (!foundTariff) {
                        return (
                            <TableRow tabIndex={-1} key={propertyType}>
                                <TableCell
                                    style={{ textDecoration: 'line-through', color: 'red' }}
                                    align="left"
                                    colSpan={pricesHeader.length + 1}
                                >
                                    {title}
                                </TableCell>
                            </TableRow>
                        );
                    }

                    const { enable, tariff_id, pricesModeByEstateCost } = foundTariff;

                    return (
                        <TableRow hover tabIndex={-1} key={propertyType}>
                            <TableCell align="left">
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Checkbox
                                        size="small"
                                        checked={enable}
                                        onChange={() => handleChangeEnableTariff(tariff_id, enable)}
                                        label={title}
                                    />
                                    {export_base_id === CIAN_EXPORT_BASE_ID && (
                                        <Switch
                                            checked={pricesModeByEstateCost}
                                            label={<InfoPopup>Цена на рекламу зависит от цены объекта</InfoPopup>}
                                            onChange={() => handleChangeModeByCost(tariff_id, pricesModeByEstateCost)}
                                        />
                                    )}
                                </Box>
                            </TableCell>

                            {!pricesModeByEstateCost && <ExportFeedTariffsPrices export_base_id={export_base_id} tariff_id={tariff_id} />}
                            {pricesModeByEstateCost && (
                                <ExportFeedTariffsPricesExtendedMode export_base_id={export_base_id} tariff_id={tariff_id} />
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default observer(ExportTableTariffs);
