import { UserLinkType } from './users.types';
import { EstateFilter, EstateLinkType } from './estate.types';

export const EXPORT_REPORT_STATUS_GOOD = 1;
export const EXPORT_REPORT_STATUS_WARNING = 2;
export const EXPORT_REPORT_STATUS_ERROR = 3;

export const CIAN_EXPORT_BASE_ID = 1;
export const YANDEX_EXPORT_BASE_ID = 2;
export const DOMCLICK_EXPORT_BASE_ID = 3;
export const AVITO_EXPORT_BASE_ID = 4;
export const WINNER_EXPORT_BASE_ID = 5;
export const IRR_EXPORT_BASE_ID = 7;
export const GDEETOTDOM_EXPORT_BASE_ID = 8;

export type EstateExportType = {
    export_id: number;
    estate_id: number;
    export_base_id: number;
    price_id: number;
    extraPrice: boolean | null;
    bet: number | null;
    title: string | null;
    createTime: number;
    updateTime: number | null;
    expireTime: number | null;
    enable: boolean;
};

export type ExportingEstate = {
    readonly estate_id: number;
    totalCost: number;
    bases: ExportedEstate[];
    externalStatuses: ExternalStatusInBase[];
    major_user_ids: number[];
    major_users: UserLinkType[];
};

export type ExportedEstate = {
    readonly export_base_id: number;
    price_id: number;
    extra?: boolean | null;
    bet?: number | null;
    title?: string | null;
    expireTime: number;
    publishTime?: number;
};

export type ExternalStatusInBase = {
    export_base_id: number;
    externalStatus: ExportExternalStatus | null;
};

export type ExportExternalStatus = {
    report_estate_id: number;
    status: number;
    externalId: string | null;
    message: string | null;
    reportTime: number;
};

export type ExportingBaseStrict = {
    readonly export_base_id: number;
    name: string;
    image: string;
    config: string;
    format: 'cian' | 'yandex' | 'avito' | 'irr' | 'nebo' | 'wordpress';
    feedurl: string;
    regionName: string;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type ExportingBase = ExportingBaseStrict & {
    regionIds: number[];
    tariffs: ExportingTariff[];
};

export type ExportingBaseStatistics = {
    readonly export_base_id: number;
    countActiveEstates: number;
};

export type ExportingBaseFiltered = ExportingBaseStrict & {
    tariff: ExportingTariff;
};

export type ExportingTariffInBase = {
    readonly tariff_id: number;
    export_base_id: number;
    region_id: number;
    type: number;
    propertyType: number;
    pricesModeByEstateCost: boolean;
    enable: boolean;
};

export type ExportingTariff = ExportingTariffInBase & {
    prices: ExportingTariffPrice[];
};

export type ExportingTariffStatistics = {
    readonly tariff_id: number;
    prices: ExportingTariffPriceStatistics[];
};

export type ExportingTariffPriceByEstateCost = {
    price_cost_id: number;
    price_id: number;

    minEstateCost: number | null;
    maxEstateCost: number | null;

    price: number;
};

export type ExportingTariffPriceInBase = {
    readonly price_id: number;
    tariff_id: number;
    name: string;
    minDays: number;
    dailyPrice: number;
    extraName: string;
    extraPrice: number;
    isPremium: boolean;
};

export type ExportingTariffPrice = ExportingTariffPriceInBase & {
    estateCosts: ExportingTariffPriceByEstateCost[];
    countActiveEstates?: number;
};

export type ExportingTariffPriceStatistics = {
    readonly price_id: number;
    countActiveEstates: number;
};

export type ExportEstateHistory = {
    days: Array<ExportDayEstate>;
    totalCost: number;
};

export type ExportDayEstate = {
    dayCost: number;
    time: number;
    transactions: ExportTransaction[];
    calls: CallTransaction[];
};

type Transaction = {
    readonly transaction_id: number;
    export_base_id: number;
    price: number;
    assigned_user_id: number;
    redeemTime: number | null;
    executeTime: number;
    estate_id: number;
    isPaid: boolean; // сотрудник вернул деньги за транзакцию. Устанавливается в момент списания
};

export type ExportTransactionInBase = Transaction & {
    export_id: number;
    extra: boolean | null;
};

export type ExportTransaction = ExportTransactionInBase & {
    assigned_user: UserLinkType;
};

export type CallTransactionInBase = Transaction & {
    readonly call_id: number;
};

export type CallTransaction = CallTransactionInBase & {
    assigned_user: UserLinkType;
};

export type ExportUserHistory = {
    list: ExportUserDay[];
    count: number;
};

export type ExportUserDay = {
    dayEstates: ExportUserEstate[];
    dayCalls: ExportUserEstate[];
    dayCost: number;
    time: number;
};

export type ExportUserEstate = {
    estate: EstateLinkType;
    cost: number;
    redeemTime: number | null;
};

type ExportReportsAnswerError = {
    estate_id: number;
    message: string;
};

export type ExportReportsAnswer = {
    export_base_id: number;
    goodCount: number;
    errors: ExportReportsAnswerError[];
    warnings: ExportReportsAnswerError[];
};

export type Region = {
    readonly region_id: number;
    title: string;
    type: string;
    timezone?: string | null;
    coordinates?: { x: number; y: number } | null;
};

export type TestReportAnswer = {
    goodCount: number;
    errorsCount: number;
    warningsCount: number;
};

export type FeedFilterType = Pick<EstateFilter, 'type' | 'propertyTypes' | 'group_id' | 'major_user_id' | 'regionId'>;

export type ExportingBaseFeed = {
    feed_id: number;
    export_base_id: number;
    name: string;
    format: 'cian' | 'yandex' | 'avito' | 'irr' | 'nebo' | 'wordpress';
    feedurl: string;
    searchParams: string;
    config: string;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type TariffPriceHeaderOptions = {
    name: string;
    minDays: number;
    isPremium: boolean;
};

export type AvitoXLPrices = {
    x10_1: number;
    x10_7: number;
    x2_1: number;
    x2_7: number;
    x5_1: number;
    x5_7: number;
};
