import React, { SyntheticEvent } from 'react';

import kanbanStore from '~/stores/kanbanStore';

import { Modal, ModalBtnAction, ModalContent } from '~ui/Modal';
import FilterContactsKanbanMoreDetails from './FilterContactsKanbanMoreDetails';
import commonStore from '~/stores/commonStore';
import { AWAIT_LOADER_SHOW_TIME_MS } from '~/components/Base/LoaderAwait';

const FilterContactsKanbanModal = ({ onClose }: { onClose: VoidFunction }) => {
    const showListAndSave = () => {
        kanbanStore.saveListSearch();
        onClose();
    };

    const handleReset = () => {
        kanbanStore.startLoading();
        kanbanStore.clearFilter();
        // чтобы LoaderAwait отобразился
        setTimeout(() => {
            kanbanStore.debounceFilterFetch();
        }, AWAIT_LOADER_SHOW_TIME_MS);
    };

    const handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: string; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        // @ts-ignore
        kanbanStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    const handleCheckbox = (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => {
        event.preventDefault();
        // @ts-ignore
        kanbanStore.changeFilter(name, checked);
    };

    const { loadingList, listCount } = kanbanStore;

    const { isMobile } = commonStore;
    const modalActions: ModalBtnAction[] = [
        { onClick: handleReset, label: 'Очистить', variant: 'text' },
        {
            onClick: showListAndSave,
            label: `Показать и Сохранить`,
            variant: 'contained',
            color: 'secondary',
            loading: loadingList
        }
    ];

    return (
        <Modal
            fullScreen={isMobile}
            header="Уточните детали для поиска"
            onClose={onClose}
            actions={modalActions}
            maxWidth="sm"
            contrastHeader
        >
            <ModalContent dividers contrast>
                <FilterContactsKanbanMoreDetails handleChange={handleChange} handleCheckbox={handleCheckbox} />
            </ModalContent>
        </Modal>
    );
};

export default FilterContactsKanbanModal;
