import React from 'react';
import { observer } from 'mobx-react';

import { CallRate, CallRateCategoryOptionUsed } from '~/types/callRates.types';
import commonStore from '~/stores/commonStore';
import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import UserAvatar from '~ui/UserAvatar';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';

const CommentItemText = ({ title, comment }: { title: string; comment: string }) => (
    <p>
        <Typography component="span" variant="body2" color="textPrimary">
            {title}
        </Typography>
        {` — ${comment}`}
    </p>
);

const CallListensItemOptionsTable = ({ optionsUsed }: { optionsUsed: CallRateCategoryOptionUsed[] }) => {
    if (!optionsUsed.length) return null;

    const positiveOptions = optionsUsed.filter(o => o.value === 1);
    const negativeOptions = optionsUsed.filter(o => o.value === 2);

    return (
        <Box display="flex" flexDirection="row" style={{ gap: '1rem' }}>
            <Box>
                {positiveOptions.map(({ category_option_id }) => (
                    <Typography key={category_option_id} variant="body2">
                        <Box display="flex" alignItems="center" style={{ gap: '0.5rem', color: 'darkgreen' }}>
                            <CheckIcon fontSize="small" />
                            {callRateCategoryStore.getOptionTitle(category_option_id)}
                        </Box>
                    </Typography>
                ))}
            </Box>
            <Box>
                {negativeOptions.map(({ category_option_id }) => (
                    <Typography key={category_option_id} color="error" variant="body2">
                        <Box display="flex" alignItems="center" style={{ gap: '0.5rem' }}>
                            <ClearIcon fontSize="small" />
                            {callRateCategoryStore.getOptionTitle(category_option_id)}
                        </Box>
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

const CallListensItem = ({ callRate }: { callRate: CallRate }) => {
    const { user, category_id, rate, improvementSuggestions, positiveFeedback, comment, categoryOptionsUsed } = callRate;

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <UserAvatar
                    firstName={user.firstName}
                    lastName={user.lastName}
                    src={user.avatarUrl}
                    size={commonStore.isMobile ? 36 : 48}
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
                        <UserLinkWithPreview user={user} icon={false} />
                        {rate && <Rating value={rate} max={5} readOnly />}
                        {category_id && (
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center" style={{ gap: '0.5rem' }}>
                                    <Typography variant="caption" color="textSecondary">
                                        Критерий:
                                    </Typography>
                                    <Typography variant="subtitle2" color="textPrimary">
                                        {callRateCategoryStore.findById(category_id)?.title}
                                    </Typography>
                                </Box>
                                <CallListensItemOptionsTable optionsUsed={categoryOptionsUsed} />
                            </Box>
                        )}
                    </Box>
                }
                secondary={
                    <>
                        {positiveFeedback && <CommentItemText title="Понравилось" comment={positiveFeedback} />}
                        {improvementSuggestions && <CommentItemText title="Можно улучшить" comment={improvementSuggestions} />}
                        {comment && <CommentItemText title="Комментарий" comment={comment} />}
                    </>
                }
            />
        </ListItem>
    );
};

export default observer(CallListensItem);
